import { imgScanningCv } from "@assets/images";
import React from "react";

function ScanningCV() {
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="relative mt-4">
        <img
          src={imgScanningCv}
          className="w-[90%] xl:w-[44vw] overflow-hidden"
        />
        <div className="line absolute bg-blue3B h-[3px] w-[120%] left-[-10%] z-10" />
      </div>
    </div>
  );
}
export default React.memo(ScanningCV);
