import { useState, useCallback } from "react";

function useBoolean(defaultValue?: boolean): [boolean, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(defaultValue || false);

  const onShow = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onHide = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, onShow, onHide];
}

export default useBoolean;
