/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";

function ErrorBoundaryFallback({ error }: { error: Error }) {
  const { t: trans } = useTranslation();
  const handleGoBack = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="__text pt-40 pb-40">
        <h2 className="fs-24 fw-700 color-grey-strong">{trans`errorBoundaryDesc`}</h2>
        {process.env.REACT_APP_NODE_ENV &&
          process.env.REACT_APP_NODE_ENV === "dev" && (
            <p className="mb-0 color-neutral-600">
              <pre>{error.message}</pre>
            </p>
          )}
      </div>
      <button
        className="flex align-center justify-center fs-14 h-40 pt-13 pb-13 pl-24 pr-24 w-180 fw-700"
        onClick={handleGoBack}
      >
        {trans`errorBoundary`}
      </button>
    </div>
  );
}

export default ErrorBoundaryFallback;
