import { ROUTE_PATH } from "@utils/constants";
// import i18next from "i18next";
import SelectCvPage from "@pages/select_cv/SelectCvPage";
import { RouteConfigProps } from "./routes";

export const aiMatchingRoute: RouteConfigProps[] = [
  {
    id: "AiMatching",
    name: "AiMatching",
    path: ROUTE_PATH.AI_MATCHING,
    component: SelectCvPage,
    isProtected: true,
    isMenu: false
  }
];
