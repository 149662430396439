/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonObjectType } from "@globalTypes/globalTypes";
import { DOMAIN_API_JOBSITE } from "./constants";
import { StringArraySupportOption } from "prettier";

export const minimizeString = (str: any) => {
  return String(str ?? "")
    .replace(/[\n\s]/g, "")
    .toLowerCase()
    .normalize("NFD")
    .replace(/đ/g, "d")
    .replace(/[\u0300-\u036f]/g, "");
};

export const convertBlobToBase64 = (blob: any) => {
  return new Promise((resolve: (v: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
  });
};

export const removeEmptyValues = (obj: CommonObjectType): any => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeEmptyValues(obj[key]);
      if (Object.keys(obj[key as string]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const formatFileSize = (size?: number) => {
  if (!size) return "";

  const units = ["B", "KB", "MB", "GB", "TB"];
  let index = 0;
  while (size >= 1024) {
    size /= 1024;
    index++;
  }
  return `${size.toFixed(0)} ${units[index]}`;
};

export const removeNotANumber = (e: any) => {
  return String(e?.target?.value || e).replace(/[^0-9]/g, "");
};

export const convertStringToNumber = (e: any) => {
  const res = removeNotANumber(e);
  return res.trim() ? +res : null;
};

export function getFileFormatFromUri(uri: string) {
  const formatMatch = uri.match(/\.([^.]+)$/);
  if (formatMatch) {
    return formatMatch[1];
  } else {
    return "unknown";
  }
}

export const addPrefixAndSuffixString = ({
  value,
  value1,
  suffix,
  suffix1
}: {
  value: string;
  value1: string;
  suffix: string;
  suffix1: string;
}) => {
  return value || value1
    ? [value ? `${suffix} ${value}` : "", value1 ? `${suffix1} ${value1}` : ""]
        .filter((item) => !!item)
        .join(" - ")
    : "";
};

export const convertTimerToSecond = (timer: string) => {
  const [hour, minute, second] = timer.split(":");
  return +hour * 3600 + +minute * 60 + +second;
};
export const convertMinuteToHour = (mins_num: number) => {
  const hours = Math.floor(mins_num / 60);
  const minutes = mins_num - hours * 60;

  return (
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":00"
  );
};

export const convertHourToMinute = (timer: string) => {
  const [hour, minute] = timer.split(":");

  return Number(+hour * 60 + +minute);
};

export const deepCopyObject = (
  object: Array<
    Record<string, string | number | Record<string, number | string>>
  >
) => {
  return JSON.parse(JSON.stringify(object));
};

export const getImageUrl = (path?: StringArraySupportOption | string) => {
  if (!path) return undefined;
  return `${DOMAIN_API_JOBSITE}/cdn/file?filePath=${path}`;
};
