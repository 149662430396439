/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import useBoolean from "./useBoolean";

export interface ModalRef<T> {
  onShow: (params?: T) => void;
  onHide: () => void;
}

function ForwardRefContent<T>(
  props: T,
  ref: React.Ref<T>,
  Children: (props: any) => JSX.Element
) {
  const [isShow, onShow, onHide] = useBoolean(false);
  const [initParams, setInitParams] = useState<T>();

  useImperativeHandle<any, ModalRef<T>>(ref, () => {
    return {
      onShow: (params?: T) => {
        onShow();
        setInitParams(params);
      },
      onHide: onHide
    };
  });

  const renderChildren = useMemo(() => {
    return <Children {...props} {...initParams} />;
  }, [Children, initParams, props]);

  if (!isShow) {
    return;
  }
  return renderChildren;
}

export const ForwardRefHoc: <T>(
  children: (props: T) => JSX.Element
) => React.ForwardRefExoticComponent<React.RefAttributes<unknown>> = (
  children: (props: any) => JSX.Element
) => forwardRef((props, ref) => ForwardRefContent(props, ref, children) as any);
