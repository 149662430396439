import { useAppDispatch } from "@apps/hooks";
import { useSocialLoginMutation } from "@apps/services/authService";
import { storageServiceApi } from "@apps/services/storageService";
import { imgEllipse } from "@assets/images";
import { icGoogle } from "@assets/svg";
import { ForwardRefHoc } from "@hooks/ForwardRefHoc";
import { MAX_SCAN_TIMES, ROUTE_PATH } from "@utils/constants";
import { auth, googleProvider } from "@utils/firebase";
import { Spin } from "antd";
import { signInWithPopup } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { modalLoginSocialRef } from "../HomePage";
import { ModalLoginSocialContainer } from "../styles";

function ModalLoginSocial({ scanTimes }: { scanTimes: number }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [socialLogin, { isLoading }] = useSocialLoginMutation();
  const dispatch = useAppDispatch();

  const onLoginGoogle = async () => {
    try {
      if (isLoading) {
        return;
      }
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      if (!user) {
        return;
      }
      const token = await user.getIdToken(true);
      await socialLogin({
        token
      }).unwrap();
      navigate(ROUTE_PATH.AI_MATCHING);
      if (pathname.includes(ROUTE_PATH.AI_MATCHING)) {
        onHide();
        dispatch(storageServiceApi.util.invalidateTags(["scanTimes"]));
      }
    } catch (error) {
      //
    }
  };

  const onHide = () => {
    modalLoginSocialRef.current?.onHide();
  };
  return (
    <ModalLoginSocialContainer
      open={true}
      footer={false}
      closable={false}
      centered
      rootClassName="modal-login-social"
      onCancel={!isLoading ? onHide : undefined}
    >
      <img
        src={imgEllipse}
        className="img-ellipse absolute w-full h-full left-0 top-0"
      />
      {scanTimes >= MAX_SCAN_TIMES && (
        <>
          <div className="text-lg text-greyED text-center">
            Hết lượt trải nghiệm
          </div>
          <div className="text-xs text-greyAD mb-6 text-center">
            Vui lòng đăng nhập với tài khoản khác để tiếp tục:
          </div>
        </>
      )}
      <div
        onClick={onLoginGoogle}
        className="btn-login flex flex-row items-center justify-center gap-1.5 w-full h-10 text-[14px] font-medium text-black0A rounded-md cursor-pointer shadow-base relative"
      >
        {isLoading ? <Spin /> : <img src={icGoogle} />}
        Tiếp tục với Google
      </div>
      <div className="text-[12px] text-greyAD text-center mt-[24px] mx-[10px]">
        Bằng việc tham gia trải nghiệm, bạn đồng ý với các thông tin cập nhật
        tiếp theo từ iVIEC.
      </div>
    </ModalLoginSocialContainer>
  );
}

export default ForwardRefHoc(ModalLoginSocial);
