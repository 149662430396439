import React from "react";

function ResultHeader() {
  return (
    <>
      <div className="title md:text-[40px]/[48px] xl:text-5xl/[72px] font-semibold tracking-[-0.48px] bg-clip-text md:mt-[78px] xl:mt-[70px]">
        Kết quả phân tích
      </div>
      <div className="description text-white text-center text-2xl font-medium mt-2 hidden xl:block">
        AI tiến hành đọc nội dung trên CV của bạn và chuyển thành dữ liệu
        <br />
        có cấu trúc theo tiêu chuẩn của nghiệp vụ tuyển dụng
      </div>
      <div className="description text-white text-center text-[13px]/[21px] md:text-lg/[32px] font-medium mt-2 block xl:hidden">
        AI tiến hành đọc nội dung trên CV của bạn
        <br />
        và chuyển thành dữ liệu có cấu trúc theo
        <br />
        tiêu chuẩn của nghiệp vụ tuyển dụng
      </div>
    </>
  );
}
export default React.memo(ResultHeader);
