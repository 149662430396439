import { Modal } from "antd";
import styled from "styled-components";
export const generateBorderDashCss = (strokeColor: string) => {
  return `url(
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='${strokeColor}' stroke-width='1.3' stroke-dasharray='4' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e"
  )`;
};
export const SelectCvContainer = styled.div`
  .title {
    text-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.32),
      0px 0px 0px rgba(255, 255, 255, 0.1);
    background: linear-gradient(90.26deg, #5fe30f 21.42%, #01f1e3 79.45%),
      conic-gradient(from 173.1deg at 50% 50%, #08a0b1 0deg, #00d6a8 360deg),
      linear-gradient(90.58deg, #39e65f 12.83%, #9cacfb 89.24%, #9cfbbd 89.24%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 992px) {
    .text-paragraph {
      margin-bottom: 4px !important;
    }
  }
  .cv-type {
    border: 1px solid rgba(62, 64, 91, 0.4);

    background: linear-gradient(
      134deg,
      rgba(29, 30, 43, 0.5) -0.02%,
      rgba(29, 30, 43, 0.45) 52.92%,
      rgba(29, 30, 43, 0.42) 105.65%
    );
  }
  .description {
    background: linear-gradient(
      121deg,
      #fff 31.35%,
      rgba(255, 255, 255, 0.65) 107.52%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .line {
    filter: blur(8px);
    animation: slideline 4s ease-in-out infinite;
    -webkit-animation: slideline 4s ease-in-out infinite;
  }
  .btn-footer {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.06) 100%
      ),
      #27282d;
    padding: 14px 20px;
    height: 48px;
    border-radius: 6px;
    box-shadow:
      0px 2px 2px 0px rgba(0, 0, 0, 0.4),
      0px 1px 1px 0px rgba(0, 0, 0, 0.4),
      0px 0px 0px 1px rgba(255, 255, 255, 0.12),
      0px -1px 0px 0px rgba(255, 255, 255, 0.04),
      0px 1px 0px 0px rgba(255, 255, 255, 0.04);
    &:hover {
      background: var(
        --Button-Dark-button-transparent-hover,
        #27282d
      ) !important;
    }
  }
  .ant-progress-text {
    display: none;
  }
`;

export const UploadCvContainter = styled.div`
  background-image: ${generateBorderDashCss("rgb(53, 55, 60)")};
  &:hover {
    background-image: ${generateBorderDashCss("rgb(96, 165, 250)")};
  }
`;

export const ModalTemplateWrapper = styled(Modal)`
  transform: translateY(-100px);
  bottom: 0;
  width: fit-content !important;
  padding-bottom: 0;

  .choose-cv {
    padding: 16px;
    border: 1.5px solid rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    background: linear-gradient(
      180deg,
      rgba(245, 250, 255, 0.08) 0%,
      rgba(244, 249, 255, 0.04) 100%
    );
    .cv-item {
      width: 168px;
      height: 238px;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid rgba(62, 64, 91, 0.4);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 8.56%,
        rgba(66, 66, 66, 0.04) 67.13%,
        rgba(3, 7, 18, 0.9) 100%
      );
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 16px;
      position: relative;

      .cv-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: fill;
        transition: all 0.3s ease-in-out;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 8.56%,
          rgba(66, 66, 66, 0.04) 67.13%,
          rgba(3, 7, 18, 0.9) 100%
        );
      }
    }
    .ant-btn {
      background: var(
        --Button-Dark-button-neutral,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.06) 100%
        ),
        #27282d
      );
      border-radius: var(--Button-Base-radius, 6px);
      box-shadow:
        0px 2px 2px 0px rgba(0, 0, 0, 0.4),
        0px 1px 1px 0px rgba(0, 0, 0, 0.4),
        0px 0px 0px 1px rgba(255, 255, 255, 0.12),
        0px -1px 0px 0px rgba(255, 255, 255, 0.04),
        0px 1px 0px 0px rgba(255, 255, 255, 0.04);
      &:hover {
        background: var(
          --Button-Dark-button-neutral-hover,
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.06) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          #35373c
        );

        box-shadow:
          0px 2px 2px 0px rgba(0, 0, 0, 0.4),
          0px 1px 1px 0px rgba(0, 0, 0, 0.4),
          0px 0px 0px 1px rgba(255, 255, 255, 0.12),
          0px -1px 0px 0px rgba(255, 255, 255, 0.04),
          0px 1px 0px 0px rgba(255, 255, 255, 0.04);
      }
    }
  }
  .template-content {
    display: flex;
    flex-direction: row;
    gap: var(--base-size-320, 32px);
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding-bottom: 15px;
  }
  .ant-modal-content {
    background: rgba(3, 7, 18, 0.92);
    box-shadow:
      0px 8px 16px 0px rgba(0, 0, 0, 0.32),
      0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: var(--Card-radius, 8px);
    padding: 24px;
  }

  .btn-close {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    height: auto;
    &:hover {
      background: var(
        --Button-Dark-button-transparent-hover,
        #27282d
      ) !important;
    }
  }
  @media (max-width: 1200px) {
    max-width: calc(100vw - 32px) !important;
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-content {
      padding: 16px;
    }
    .template-content {
      overflow: auto;
      gap: 16px;
      align-items: unset;
      justify-content: unset;
      .choose-cv {
        padding: 16px;
        .cv-item {
          width: 165px;
          height: 247.5px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .cv-item {
      width: 110px !important;
      height: 165px !important;
    }
  }

  @media (max-width: 767px) {
    top: 70%;
    margin: 8px 16px !important;
    .ant-modal-content {
      padding: 8px;
    }
    .template-content {
      gap: 8px;
      .choose-cv {
        padding: 8px;
        .cv-item {
          width: 78px !important;
          height: 109px !important;
          margin-bottom: 0;
        }
        .ant-btn {
          display: none;
        }
      }
    }
  }
`;
