import Divider from "./Divider";

export default function PositionAndEvaluate({
  data
}: {
  data: Array<{ text: string; value: number; subValue: string }>;
}) {
  return (
    <div className="bg-base p-4 xl:p-6 flex-1 shadow-base border border-grey2E rounded-lg">
      {Array.isArray(data) &&
        data.map((item, index) => (
          <>
            <div className="flex flex-row items-center justify-between font-space-mono py-4 p-0 xl:px-6">
              <span className="text-[14px]/[20px] xl:text-base/[20px] text-greyED font-normal">
                {item?.text}
              </span>
              <span className="text-[15px]/[20px] xl:text-xl/[20px] text-green10 font-bold ">
                {item?.subValue}%
              </span>
            </div>
            {index !== data?.length - 1 && <Divider />}
          </>
        ))}
    </div>
  );
}
