export enum LoginStepEnum {
  stepOne,
  stepTwo
}

export enum ResetPasswordState {
  loading,
  verifyLinkFailed,
  verifyLinkSuccess
}

export enum AcctiveAccount {
  loading,
  verifyActiveSuccess,
  verifyActiveFailed
}

export enum viewMode {
  "viewGroupMember", //0
  "createGroupMember", //1
  "updateGroupMember", //2
  "deleteGroupMember", //3
  "addGroupMember", //4
  "addMember", //5
  "deleteMember", //6
  "createMember", //7
  "viewMember", //8
  "updateMember", //9
  "createJobDescription", //10
  "editJobDescription", //11
  "deleteJobDescription" //12
}

export enum addCandidatesToRecruitmentStepEnum {
  "first",
  "second"
}

export enum ViewModeDrawer {
  "detail",
  "create",
  "update",
  "delete"
}

export enum CompanyVerifyStep {
  Intro,
  EnterInfo
}

export enum CompanyStatus {
  NotVerify,
  PendingVerified,
  Verified
}

export enum MemberInvitationStatus {
  Sending,
  SendingFailed,
  SendingTimeout,
  SendingSucceed,
  Accept
}

export enum ScanCVStatus {
  Sending,
  SendingSucceed,
  SendingFailed,
  Duplicate,
  ALL
}

export enum RecruitmentContractStatus {
  Operating,
  Operated,
  NotOperate
}

export enum SalaryShowTypeContract {
  Agreement,
  Minimum,
  Max,
  SalaryDetails,
  NoSalary
}

export enum CurrentUnitTypeContract {
  Vnd,
  Usd
}

export enum GenderTypeContract {
  Male,
  Female,
  Other
}

export enum WorkExperienceType {
  NotRequired,
  NoExperience,
  LessThanOne,
  BetweenOneAndThree,
  BetweenThreeAndFive,
  BetweenFiveAndSeven,
  BetweenSevenAndTen,
  OverTen
}

export enum LanguageTypeContract {
  Vietnamese,
  English,
  Japanese,
  Chinese,
  Korean,
  French,
  Russian,
  German,
  Spanish,
  Portuguese,
  OtherLang
}

export enum WaysOfWorkingTypeContract {
  FullTime,
  PartTime,
  Remote,
  SeasonalWork,
  Freelance,
  Hybrid,
  Other
}

export enum RequiredLevelTypeContract {
  BoardOfManagement,
  Director,
  ViceDirector,
  Manager,
  Leader,
  Specialist,
  Staff,
  Collaborator,
  Intern
}

export enum RecruitmentStatus {
  Enable,
  Disable,
  Completed
}

export enum EmailOfferStatus {
  IsDraf,
  IsActive
}

export enum RecruitmentApplyByCvType {
  File,
  Url
}

export enum RecruitmentApplyFormDataTypeContract {
  RecruitmentApplyFormDataPhoneNumber,
  RecruitmentApplyFormDataParagraph,
  RecruitmentApplyFormDataAddress,
  RecruitmentApplyFormDataFile,
  RecruitmentApplyFormDataTime,
  RecruitmentApplyFormDataSortParagraph,
  RecruitmentApplyFormDataMultipleChoice,
  RecruitmentApplyFormDataSingleChoice
}

export enum OrganizationPolicyStatus {
  Stop,
  Active
}

export enum RecruitmentProcessStatus {
  Complete,
  Doing,
  Waiting,
  NotAchieved
}

export enum FindJobStatus {
  LookingForAJob,
  NotLookingForAJobYet,
  Unknown
}

export enum OfferResponse {
  Accept,
  Reject,
  NotResponse,
  Sending
}

export enum ExamStatus {
  Passed,
  Failed,
  NotGraded,
  NotExamine
}

export enum InterviewStatus {
  Passed,
  Failed,
  NotEvaluate,
  NotInterview
}

export enum MarriedStatus {
  Single,
  Married,
  Other
}

export enum CandidateMaritalStatusContract {
  Single,
  Married,
  Other
}

export enum ProcessStatus {
  Waiting,
  Doing,
  Complete
}

export enum CandidateApplyStatusContract {
  ApplyByCv,
  ApplyByForm,
  ApplyByFormWarehouse
}

export enum CandidateTaggingType {
  Keyword,
  Skill
}

export enum CandidateTaggingUsageType {
  Applied,
  NotApplied
}

export enum RoleGroupType {
  Default,
  Custom
}
export enum ChartUnit {
  candidate,
  percentage
}

export enum DashboardFilter {
  today,
  last7Days,
  last28Days,
  fullTime,
  customTime
}

export enum AcceptOfferStatusEnum {
  Accept,
  Reject,
  NotResponse
}

export enum SubscriptionPackageTypeEnum {
  Free,
  Pro,
  Optimize,
  Plus,
  Premium,
  Enterprise,
  Customize
}

export enum RecruitmentPublishDataType {
  Carerr,
  Jobsite
}

export enum ExamType {
  Fixed,
  Random
}

export enum ShareCandidateType {
  Unknown,
  Public,
  Private
}

export enum ClosingTheReviewType {
  Pass,
  Consider,
  NotAchieved
}

export enum QuestionTypeContract {
  MultipleChoiceWithOneCorrect,
  MultipleChoiceWithManyCorrect,
  Essay
}

export enum ExaminationMapQuestionGroupTypeContract {
  MultipleChoice,
  Essay
}

export enum ExaminationShowType {
  OnePerPage,
  AllInPage
}

export enum Status {
  deActive,
  anActive
}

export enum EvaluateFormButtonEnum {
  OneButton,
  TwoButton
}
export enum OrderStatusEnum {
  NotActivated,
  Activated,
  Canceled
}

export enum WorkspaceEmailTemplateEnum {
  Offer,
  Apply,
  RejectResult,
  Calendar,
  ReviewCalendar,
  Examination
}

export enum EmailOfferSendingStatus {
  NotSending,
  Draft,
  Sent
}

export enum WorkspaceIntegrationTokenScopeTypeContract {
  ReadRecruitment,
  WriteRecruitment,
  ReadCandidate,
  WriteCandidate
}

export enum PaymentOrderStatusEnum {
  Faild,
  Success
}

export enum PaymentStatusEnum {
  Unpaid, //chưa thánh toán
  PartialPayment, //thanh toán một phần
  paid //đã thanh toán
}

export enum OrderBillStatusEnum {
  Proccessing,
  Success,
  Failed,
  Canceled
}

export enum ActiveAccountEnum {
  Default,
  RecruitmentFree
}

export enum TypeIntroModal {
  Login,
  ScheduleADemo,
  PostForFree
}
