import { icArrowUp } from "@assets/svg";
import React from "react";

function BtnViewMore({ onHideCollapse }: { onHideCollapse: () => void }) {
  return (
    <>
      <div className="absolute bottom-0 bg-linear w-full h-full"></div>
      <div
        onClick={onHideCollapse}
        className={`btn-collapse pt-6 xl:pt-[14px] pb-4 xl:pb-[38px] z-10`}
      >
        Xem chi tiết
        <img className="rotate-180" src={icArrowUp} />
      </div>
    </>
  );
}
export default React.memo(BtnViewMore);
