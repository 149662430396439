import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authServiceApi } from "@apps/services/authService";

import { AuthProps } from "@globalTypes/globalTypes";
import {
  readAccessToken,
  readRefreshToken,
  saveAccessToken,
  saveRefreshToken
} from "@utils/localStorage";

const initialState = {
  userProfile: null,
  permissions: [],
  access_token: readAccessToken() || null,
  refresh_token: readRefreshToken() || null
} as AuthProps;

const authSliceReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { token, refreshToken }
      }: PayloadAction<{
        token?: string;
        refreshToken?: string;
      }>
    ) => {
      if (token) {
        state.access_token = token;
      }
      if (refreshToken) {
        state.refresh_token = refreshToken;
      }
    },

    logout: (state) => {
      state.userProfile = null;
      state.permissions = [];
      state.access_token = null;
      state.refresh_token = null;
    }
  },

  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    builder.addMatcher(
      authServiceApi.endpoints.authLogin.matchFulfilled,
      (state, { payload }) => {
        saveAccessToken(payload.token);
        saveRefreshToken(payload.refreshToken);

        return {
          ...state,
          access_token: payload.token,
          refresh_token: payload.refreshToken
        };
      }
    ),
      builder.addMatcher(
        authServiceApi.endpoints.socialLogin.matchFulfilled,
        (state, { payload }) => {
          saveAccessToken(payload.token);
          saveRefreshToken(payload.token);
          return {
            ...state,
            access_token: payload.token,
            refresh_token: payload.token
          };
        }
        // eslint-disable-next-line no-sequences
      );
  }
});
export const { setCredentials, logout } = authSliceReducer.actions;
export default authSliceReducer.reducer;
