/* eslint-disable @typescript-eslint/no-explicit-any */
import { imgLight } from "@assets/images";
import { icArrowUp } from "@assets/svg";
import useBoolean from "@hooks/useBoolean";
import { fixedNumber, roundNumber } from "@utils/helperFuntions";
import React, { useMemo } from "react";
import BtnViewMore from "./components/BtnViewMore";
import ChartReasonableRate from "./components/ChartReasonableRate";
import JobInfo from "./components/JobInfo";
import PositionAndEvaluate from "./components/PositionAndEvaluate";
import ResultHeader from "./components/ResultHeader";
import { ResultAndAnalystContainer } from "./styles";

function JobAndAnalyst({
  onHideDetailJob,
  dataJobSuggestion
}: {
  onHideDetailJob: () => void;
  dataJobSuggestion: any;
}) {
  const [isCollapse, onCollapse, onHideCollapse] = useBoolean(true);
  const {
    position,
    experience,
    skills,
    education,
    location,
    categories,
    salary,
    gender
  } = dataJobSuggestion || {};

  const data = useMemo(() => {
    return [
      {
        text: "Vị trí",
        value: roundNumber(position),
        subValue: fixedNumber(position)
      },
      {
        text: "Kinh nghiệm",
        value: roundNumber(experience),
        subValue: fixedNumber(experience)
      },
      {
        text: "Kỹ năng",
        value: roundNumber(skills),
        subValue: fixedNumber(skills)
      },
      {
        text: "Học vấn",
        value: roundNumber(education),
        subValue: fixedNumber(education)
      },
      {
        text: "Khu vực",
        value: roundNumber(location),
        subValue: fixedNumber(location)
      },
      {
        text: "Ngành nghề",
        value: roundNumber(categories),
        subValue: fixedNumber(categories)
      },
      {
        text: "Mức lương",
        value: roundNumber(salary),
        subValue: fixedNumber(salary)
      },
      {
        text: "Giới tính",
        value: roundNumber(gender),
        subValue: fixedNumber(gender)
      }
    ];
  }, [
    education,
    experience,
    gender,
    categories,
    position,
    salary,
    skills,
    location
  ]);

  return (
    <ResultAndAnalystContainer className="flex flex-col items-center xl:h-full xl:w-[1098px]">
      <ResultHeader />
      <div className="flex flex-col xl:flex-row bg-grey1D/[0.8] p-4 xl:p-12 shadow-base rounded-2xl w-full xl:w-[1098px] mt-[45px] md:mt-16 gap-6 xl:gap-[62px] relative">
        <ChartReasonableRate data={data} score={dataJobSuggestion?.score} />
        <img src={imgLight} className="absolute top-5 left-0 w-full h-full" />
        <PositionAndEvaluate data={data} />
      </div>
      <div className="flex flex-col bg-grey1D/[0.8] mt-6 relative rounded-lg">
        <div
          className={`flex flex-col ${isCollapse && "max-h-[392px] overflow-hidden relative"}`}
        >
          <div
            className={`flex flex-row rounded-2xl w-full xl:w-[1098px] gap-2 ${isCollapse && "max-h-[392px] overflow-hidden shadow-base relative"} justify-center`}
          >
            <JobInfo dataJobSuggestion={dataJobSuggestion} />
          </div>
          {isCollapse && <BtnViewMore onHideCollapse={onHideCollapse} />}
        </div>
        <>
          {isCollapse && (
            <div className="absolute bottom-0 bg-linear w-full h-full"></div>
          )}
          <div className="flex flex-row gap-9 justify-center border-t border-t-grey2E z-10 xl:border-t-0">
            <div
              onClick={onHideDetailJob}
              className={`btn-collapse py-[22px] z-10 w-fit px-[12px]`}
            >
              <img className="hidden md:block -rotate-90" src={icArrowUp} />
              Quay lại
            </div>
            <div
              onClick={isCollapse ? onHideCollapse : onCollapse}
              className={`btn-collapse py-[22px] z-10 w-fit px-[12px]`}
            >
              {isCollapse ? "Xem chi tiết" : "Thu gọn"}
              <img className="hidden md:block rotate-90" src={icArrowUp} />
            </div>
          </div>
        </>
      </div>
    </ResultAndAnalystContainer>
  );
}

export default React.memo(JobAndAnalyst);
