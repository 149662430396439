import { icBlueDot } from "@assets/svg";
import { Experience } from "@globalTypes/globalTypes";
import React from "react";

function ItemExperience({
  item,
  isShowLine
}: {
  item: Partial<
    Experience & { JobDescription?: React.ReactElement; CompanyName?: string }
  >;
  index: number;
  isShowLine: boolean;
}) {
  const { JobDescription, Des, Position, TimePeriod, CompanyName } = item || {};
  if (!TimePeriod && !CompanyName && !Des && !Position && !JobDescription) {
    return <></>;
  }
  return (
    <div className="flex flex-row gap-[14px]">
      <div className="flex flex-col gap-1 items-center mb-1 mt-[2px]">
        <img src={icBlueDot} className="min-w-5 h-5" />
        {isShowLine && <div className="mx-1 w-[1px] h-full bg-grey2E"></div>}
      </div>
      <div className={`flex flex-col ${isShowLine && "pb-10"}`}>
        {!!TimePeriod && (
          <span className="text-xs/[20px] text-greyAD">{TimePeriod}</span>
        )}
        {!!CompanyName && (
          <span
            className={`text-sm font-medium text-greyED ${TimePeriod && "mt-1"}`}
          >
            {CompanyName}
          </span>
        )}
        {!!Des && (
          <span className="text-sm font-medium text-greyED mt-1">{Des}</span>
        )}
        {!!Position && (
          <span className="text-[13px]/[20px] text-greyAD mt-4">
            {Position}
          </span>
        )}
        {!!JobDescription && (
          <span className="text-[13px]/[20px] text-greyAD mt-4">
            {JobDescription}
          </span>
        )}
      </div>
    </div>
  );
}

export default React.memo(ItemExperience);
