import React from "react";
import Divider from "./Divider";

function InfoItem({
  title,
  value,
  isHiddenDivider
}: {
  title: string;
  value: string;
  isHiddenDivider?: boolean;
}) {
  return (
    <>
      <div className="p-6 xl:p-0 w-full">
        <div className="text-[16px]/[28px] md:text-[18px]/[28px] font-semibold text-greyED self-start">
          {title}
        </div>
        <div
          className="gap-[18px] mt-4 flex flex-col recruitment-info"
          dangerouslySetInnerHTML={{
            __html: value
          }}
        />
        {!isHiddenDivider && (
          <Divider className="hidden xl:block mb-6 xl:mt-6" />
        )}
      </div>
      {!isHiddenDivider && <Divider className="block xl:hidden" />}
    </>
  );
}
export default React.memo(InfoItem);
