import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ErrorBoundaryFallback from "@components/ErrorBoundaryFallback";
import PrivateRoute from "@components/private_route/PrivateRoute";
import PublicRoute from "@components/public_route/PublicRoute";
import { routeConfig } from "@routes/routes";

function App() {
  return (
    <>
      <Routes>
        {routeConfig.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={
              route.isProtected ? (
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <PrivateRoute>
                    <route.component />
                  </PrivateRoute>
                </ErrorBoundary>
              ) : (
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <PublicRoute>
                    <route.component />
                  </PublicRoute>
                </ErrorBoundary>
              )
            }
          />
        ))}
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
