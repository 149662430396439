import React from "react";

function SelectCVHeader() {
  return (
    <>
      <div className="title text-[40px]/[48px] md:text-5xl/[72px] font-semibold tracking-[-0.48px] bg-clip-text md:mt-[78px] xl:mt-[70px]">
        AI CV Profiling
      </div>
      <div className="description text-white text-center md:text-lg/[32px] xl:text-2xl font-medium mt-2 hidden sm:block">
        Chuyển đổi nội dung CV thành dữ liệu có cấu trúc
        <br /> & Đánh giá độ phù hợp của CV với yêu cầu tuyển dụng
      </div>
      <div className="description text-white text-center text-[13px] font-medium mt-2 block sm:hidden">
        Chuyển đổi nội dung CV thành dữ liệu
        <br />
        có cấu trúc & Đánh giá độ phù hợp
        <br />
        của CV với yêu cầu tuyển dụng
      </div>
    </>
  );
}
export default React.memo(SelectCVHeader);
