/* eslint-disable @typescript-eslint/no-explicit-any */
import { imgCv, imgDetail } from "@assets/images";
import { icCalendar, icEmail, icPhone, icSwap } from "@assets/svg";
import { PageContext } from "@components/private_route/PrivateRoute";
import { PreviewCvType } from "@globalTypes/globalTypes";
import React, { useContext, useMemo, useState } from "react";
import CvPreview from "./CvPreview";
import Divider from "./Divider";
import ItemExperience from "./ItemExperience";
import RelateKeyword from "./RelateKeyword";
import Title from "./Title";
import UserInfo from "./UserInfo";

function CVInfo() {
  const { candidate } = useContext(PageContext);
  const [selectedTab, setSelectedTab] = useState(PreviewCvType.DETAIL);

  const onChangeTab = (tab: number) => () => {
    setSelectedTab(tab);
  };
  const renderTab = (item: string, index: number) => {
    return (
      <div
        className={`flex flex-1 justify-center p-[6px] text-[13px]/[20px] font-medium  ${selectedTab !== index ? "bg-black27 text-greyAD" : "border-black3C  bg-base rounded border border-black/[0.4] text-greyED"}`}
        onClick={onChangeTab(index)}
      >
        {item}
      </div>
    );
  };

  const renderMobieTab = () => {
    return (
      <>
        <div className="flex flex-row items-center gap-6 justify-center mt-4">
          <img src={imgCv} className="w-15 h-16" />
          <img src={icSwap} className="w-6 h-6" />
          <img src={imgDetail} className="w-16 h-16" />
        </div>
        <div className="flex flex-row rounded-lg mt-8 xl:mt-[18px] bg-black27 shadow-tab p-1 w-full border border-black3C z-10">
          {["Nội dung CV", "CV đã chọn"].map(renderTab)}
        </div>
      </>
    );
  };

  const renderProcess = (data: any[], title: string) => {
    const _data = Array.isArray(data)
      ? data.map((item) => ({
          CompanyName: item?.ProjectName,
          ...item,
          Description: "",
          JobDescription:
            Array.isArray(item?.Description) &&
            item?.Description.some((item: string) =>
              Object.entries(item).some((itemValue) => !!itemValue[1])
            ) ? (
              <>
                {item?.Description?.map((item: string) => (
                  <span className="block" key={item}>
                    {item}
                  </span>
                ))}
              </>
            ) : undefined
        }))
      : [];
    if (_data?.length < 1) {
      return <></>;
    }
    return (
      <>
        <Divider className="hidden xl:block" />
        <div>
          <Title title={title} className="mb-6" />
          {_data.map((item, index) => (
            <ItemExperience
              index={index}
              item={item}
              isShowLine={index !== _data?.length - 1}
            />
          ))}
        </div>
      </>
    );
  };

  const {
    Achievements,
    Educations,
    Experiences,
    RawApplicantSkills,
    ActivitiesAndProjects,
    CareerObjective,
    FullName,
    PhoneNumber,
    Email,
    DateOfBirth,
    LivingAddress
  } = candidate || {};
  const isHaveRawApplicantSkills = useMemo(() => {
    return (
      Array.isArray(RawApplicantSkills) &&
      RawApplicantSkills.some((item) =>
        Object.entries(item).some((itemValue) => !!itemValue[1])
      )
    );
  }, [RawApplicantSkills]);

  return (
    <div className="flex flex-col w-full">
      <div className="block mx-6 xl:hidden z-50">{renderMobieTab()}</div>
      <div className="flex flex-row">
        <div
          className={`flex flex-col flex-1 p-6 xl:border-r xl:border-r-grey2E ${selectedTab === PreviewCvType.DETAIL && "hidden xl:flex"}`}
        >
          <img
            src={imgCv}
            className="w-20 h-21 self-center mb-14 hidden xl:block"
          />
          <CvPreview />
        </div>
        <div
          className={`flex flex-col flex-1 px-8 pt-8 md:px-10 xl:px-6 xl:pt-6 ${selectedTab === PreviewCvType.PREVIEW && "hidden xl:flex"}`}
        >
          {" "}
          <img
            src={imgDetail}
            className="w-20 h-21 self-center mb-14 hidden xl:block"
          />
          <div className="self-start mb-7">
            <div className="text-[16px]/[28px] xl:text-[18px]/[28px] font-semibold text-greyED">
              {FullName || ""}
            </div>
            <div className="gap-3 flex flex-col mt-[13px] xl:mt-4">
              <UserInfo image={icPhone} txt={PhoneNumber || ""} />
              <UserInfo image={icEmail} txt={Email || ""} />
              <UserInfo image={icCalendar} txt={DateOfBirth || ""} />
            </div>
          </div>
          <Divider />
          <Title title="Kỹ năng" className="mb-3 xl:mb-6 mt-7" />
          <div className="flex flex-col gap-[44px] xl:gap-6">
            {isHaveRawApplicantSkills && (
              <RelateKeyword data={RawApplicantSkills} />
            )}
            {renderProcess(Experiences || [], "Kinh nghiệm")}
            <Divider className="hidden xl:block" />
            <div>
              <Title title="Học vấn" className="mb-3 xl:mb-6" />
              {Array.isArray(Educations) &&
                Educations.map((item, index) => (
                  <ItemExperience
                    index={index}
                    item={{
                      ...item,
                      CompanyName: item?.School,
                      Des: item?.Major,
                      Position: item?.Degree
                    }}
                    isShowLine={index !== Educations?.length - 1}
                  />
                ))}
            </div>
            {renderProcess(ActivitiesAndProjects || [], "Dự án và hoạt động")}
            {Achievements && Achievements?.length > 0 && (
              <>
                <Divider className="hidden xl:block" />
                <div>
                  <Title title="Thành tích" className="mb-3 xl:mb-6" />
                  <div>
                    {Achievements.map((item, index) => (
                      <ItemExperience
                        index={index}
                        item={{
                          ...item,
                          CompanyName: item?.Certificate,
                          Position: Array.isArray(item?.Description)
                            ? item?.Description.join(",")
                            : "",
                          Description: ""
                        }}
                        isShowLine={index !== Achievements?.length - 1}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            {!!LivingAddress && (
              <>
                <Divider className="hidden xl:block" />
                <div>
                  <Title title="Địa chỉ" className="mb-3 xl:mb-6" />
                  <div className="text-sm/[20px] font-medium text-greyED mt-4">
                    {LivingAddress || ""}
                  </div>
                </div>
              </>
            )}
            <Divider className="hidden xl:block" />
            <div>
              {(!!CareerObjective?.Objective ||
                !!CareerObjective?.Position) && (
                <div className="pb-4 xl:pb-[94px]">
                  <Title
                    title="Mục tiêu nghề nghiệp"
                    className="mb-3 xl:mb-6"
                  />
                  {!!CareerObjective?.Position && (
                    <div className="text-[13px]/[20px] text-greyAD mt-4">
                      {CareerObjective?.Position || ""}
                    </div>
                  )}
                  {!!CareerObjective?.Objective && (
                    <div className="text-[13px]/[20px] text-greyAD mt-4">
                      {CareerObjective?.Objective || ""}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(CVInfo);
