import { icWarning } from "@assets/svg";
import { Col, Row } from "antd";
import { toast } from "react-toastify";

const CustomToast = ({ closeToast }: { closeToast: () => void }) => (
  <Row wrap={false} align={"middle"}>
    <Col className="border-r border-grey2E pr-4">
      <h3 className="text-greyED font-medium">Hệ thống quá tải</h3>
      <p className="text-greyAD text-[13px]">
        Số lượng CV tải lên quá nhiều, thời gian xử lý lâu hơn dự kiến. Vui lòng
        thử lại sau.
      </p>
    </Col>
    <Col flex="none" className="px-6 text-[13px] text-greyAD font-medium">
      <button onClick={closeToast}>Đóng</button>
    </Col>
  </Row>
);

export const showErrorToast = () => {
  toast.info(({ closeToast }) => <CustomToast closeToast={closeToast} />, {
    icon: <img src={icWarning} />,
    closeButton: false,
    className: "toast-warning",
    hideProgressBar: true
  });
};
