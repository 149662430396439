import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCmxLNDbx-IedMyslZmycF738l9QL6d7Nc",
  authDomain: "authentication-iviec.firebaseapp.com",
  projectId: "authentication-iviec",
  storageBucket: "authentication-iviec.appspot.com",
  messagingSenderId: "669811169843",
  appId: "1:669811169843:web:7e7baecee7feebb1712146",
  measurementId: "G-RKX72JHP1S"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account"
});

const logoutFirebase = () => {
  signOut(auth);
};
export { auth, googleProvider, logoutFirebase };
