import {
  imgBackground,
  imgBackgroundMobie,
  imgLoginGoogleBg,
  imgStar
} from "@assets/images";
import { Modal } from "antd";
import styled from "styled-components";

export const HomeContainer = styled.div`
  background-image: url(${imgBackground});
  background-size: cover;
  .title {
    background: linear-gradient(90deg, #5fe30f 21.42%, #01f1e3 79.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.32),
      0px 0px 0px rgba(255, 255, 255, 0.1);
    font-style: normal;
    letter-spacing: -0.72px;
  }
  .description {
    background: linear-gradient(
      121deg,
      #fff 31.35%,
      rgba(255, 255, 255, 0.16) 107.52%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-style: normal;
    letter-spacing: -0.32px;
  }
  .button {
    background: linear-gradient(92deg, #007aff 0.16%, #20d0f4 99.88%);
  }
  @media (max-width: 640px) {
    background-image: url(${imgBackgroundMobie});
  }
`;
export const ModalLoginSocialContainer = styled(Modal)`
  .ant-modal-content {
    background-image: url(${imgStar}) !important;
    background-size: contain;
    border-radius: 16px;
    min-height: 152px;
    padding: 40px 78px !important;
    border: 1px solid rgba(65, 62, 62, 0.4);
    background: var(--bg-base);
    padding: 0;
    box-shadow:
      0px 2px 24px 0px rgba(0, 0, 0, 0.32),
      0px 16px 32px 0px rgba(0, 0, 0, 0.32),
      0px 0px 0px 1px rgba(255, 255, 255, 0.1),
      0px 0px 0px 2px rgba(46, 48, 53, 0.4) inset,
      0px 0px 0px 1px #171717 inset;
    .btn-login {
      background-image: url(${imgLoginGoogleBg}) !important;
      z-index: 1;
    }
    @media (max-width: 640px) {
      margin: 16px;
      padding: 40px 16px !important;
      border-radius: 8px;
    }
  }
`;

export const QueueInfoModalContainer = styled(Modal)`
  .ant-modal-content {
    border: 1px solid rgba(62, 64, 91, 0.4);
    background: rgba(29, 30, 43, 0.5);
  }
`;
