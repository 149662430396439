import i18next from "i18next";

export const PATH_DOMAIN: string =
  process.env.REACT_APP_API_DOMAIN ?? "https://beta-api.iviec.vn/api";
export const DOMAIN_API_JOBSITE: string =
  process.env.REACT_APP_API_DOMAIN_JOBSITE ?? "https://jobsite-api.iviec.dev";

export const PATH_DOMAIN_UPLOAD_API =
  process.env.REACT_APP_API_UPLOAD ?? "https://techday-api.iviec.dev";
export const UPLOAD_FILE_STATUS = {
  error: "error",
  success: "success",
  done: "done",
  uploading: "uploading",
  removed: "removed"
};

export const TOTAL_NUMBER_ID = 6;

let ERROR_CODE_CONVERT: Record<string, string> = {};
const dynamicErrorByLanguage = () => {
  ERROR_CODE_CONVERT = {
    IDE_05: i18next.t("oldPasswordWrong"),
    IDE_21: i18next.t("somethingWentWrong"),
    AUE_01: i18next.t("emailNotRegister"),
    WKE_05: i18next.t("domainExist"),
    IDE_06: i18next.t("wrongPasswordMessage"),
    MGE_04: i18next.t("groupNameAlreadyExists"),
    RRE_06: i18next.t("pre_06"),
    RSE_08: i18next.t("notDeleteStepRecruitment"),
    RSE_09: i18next.t("notUpdateProcessRecruitment"),
    BCE_03: i18next.t("notFoundCalendar"),
    MIE_01: i18next.t("inviteFail"),
    WKE_07: i18next.t("domainExist"),
    WKE_09: i18next.t("workspaceLocked"),
    MIE_06: i18next.t("limitMemberError"),
    OSE_06: i18next.t("limitScanCVError"),
    SSE_01: i18next.t("commonLimitError"),
    PSE_05: i18next.t("commonLimitError"),
    OGE_07: i18next.t("commonLimitError"),
    OVE_07: i18next.t("commonLimitError"),
    MIE_04: i18next.t("memberDuplicate"),
    QGE_05: i18next.t("questionGroupDuplicate"),
    TER_07: i18next.t("invalidAccountInformation"),
    QGE_04: i18next.t("questionDuplicate"),
    PME_04: i18next.t("emailExist"),
    BCE_06: i18next.t("candidateIsInterviewed"),
    RAE_05: i18next.t("reviewFormHasBeenExisted"),
    EXE_06: i18next.t("examDuplicate"),
    QEE_08: i18next.t("questionExistedExam"),
    TER_10: i18next.t("someErrorHappens"),
    OBE_04: i18next.t("someErrorHappens"),
    SOE_07: i18next.t("someErrorHappens"),
    OTE_04: i18next.t("theTagAlreadyExistsTryRenamingTheTagAndSaving"),
    MGE_07: i18next.t("atLeastOneMemberGroupContainsMember"),
    NDR_00: i18next.t("thereIsNoDataToExport"),
    RAE_06: i18next.t("noAuthorityErrorMessage")
    //insert error code in here
  };
};

dynamicErrorByLanguage();

i18next.on("languageChanged", function () {
  dynamicErrorByLanguage();
});

export { ERROR_CODE_CONVERT };

export const TIME_FORMAT = {
  MM_YYYY: "MM/YYYY",
  DD_MM_YYYY: "DD/MM/YYYY",
  HH_MM_DD_MM_YYYY: "HH:mm DD/MM/YYYY",
  DD_MM_YYY_HH_MM_SS: "DD/MM/YYYY HH:mm",
  YYYY: "YYYY",
  HH_MM: "HH:mm",
  HH_s_MM: "HH : mm",
  HH_MM_ss: "HH:mm:ss"
};

export const ROUTE_PATH = {
  // root path
  ROOT: "/",

  // auth path
  AUTH_LOGIN: "/auth/login",
  AUTH_REGISTER: "/auth/register",
  USER_PROFILE: "/user/profile",
  AI_MATCHING: "/ai-matching"
};

export const API_PATH = {
  // auth path
  LOGIN_PATH: "/auth/login",
  REGISTER_PATH: "/auth/register",
  PROFILE_PATH: "/user/profile",
  REFRESH_PATH: "/auth/refresh",
  GET_USER_FILE: "/storage/Storage/UserGetPublicFile",
  GET_TEMPLATES_CV: "/templates",
  SCAN_CV_TEMPLATES: "/scanCV/filePath",
  JOB_MATCHING_TEMPLATES: "/matching/filePath",
  UPLOAD_USER_FILE_CV: "/storage/Storage/UserUploadFileCv",
  SCAN_CV: "/scanCV",
  JOB_MATCHING: "/matching",
  GET_LIST_LOGO: "/workspace/Organization/GetOrganizationByWorkspace",
  GET_USER_PUBLIC_FILE: "/storage/Storage/UserGetPublicFile",
  SOCIAL_LOGIN: "/login",
  SCAN_TIME: "/scanTimes",
  JOB_STATUS: "/checkScanCVJobStatus",
  JOB_RESULT: "/scan"
};

export const FILE_IMAGE_TYPE = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg"
};

export const FILE_VIDEO_TYPE = {
  mp4: "video/mp4",
  mov: "video/quicktime"
};

export const FILE_DOC_TYPE = {
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
};

export const FILE_PDF_TYPE = {
  pdf: "application/pdf"
};

//https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const FILE_XLSX_TYPE = {
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel"
};

export const FILE_DEFAULT_TYPE = {
  ...FILE_IMAGE_TYPE,
  ...FILE_DOC_TYPE,
  ...FILE_PDF_TYPE,
  ...FILE_XLSX_TYPE
};

export const FILE_DOC_EXT = {
  doc: "doc",
  docx: "docx"
};

export const FILE_XLS_EXT = {
  xls: "xls",
  xlsx: "xlsx"
};

export const FILE_PPT_EXT = {
  ppt: "ppt",
  pptx: "pptx"
};

export const FIVE_MB = 200 * Math.pow(10, 6);

export const MAX_SCAN_TIMES = 3;

export const NUM_PER_PAGE = 6;
export const isMobie = window.innerWidth < 768;
export const isOtherDevince = window.innerWidth < 1240;
