/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { toast } from "react-toastify";

import { logout, setCredentials } from "@apps/slices/authSlice";
import AppTranslation from "@components/AppTranslation";
import { ErrorProps } from "@globalTypes/globalTypes";
import { API_PATH, PATH_DOMAIN } from "@utils/constants";
import { saveAccessToken, saveRefreshToken } from "@utils/localStorage";
import { RootState } from "../store";

interface AuthProps {
  access_token: null | string;
  refresh_token: null | string;
}

// create a new mutex
const mutex = new Mutex();

const baseQueryNotAuth = fetchBaseQuery({
  baseUrl: PATH_DOMAIN
});

const baseQuery = fetchBaseQuery({
  baseUrl: PATH_DOMAIN,

  prepareHeaders: async (headers, { getState }) => {
    const { access_token } = (getState() as RootState).auth;
    if (access_token) {
      headers.set("Authorization", `Bearer ${access_token}`);
    }

    return headers;
  }
});

const trimData = (body: any): any => {
  if (!body) return body;

  if (typeof body === "string") return body.trim();

  if (typeof body === "object" && !(body instanceof FormData)) {
    if (Array.isArray(body)) return body.map(trimData);

    return Object.keys(body).reduce(
      (acc, key) => ({ ...acc, [key]: trimData(body[key]) }),
      {}
    );
  }

  return body;
};

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = await baseQuery(
    typeof args === "string"
      ? args
      : { ...args, body: trimData(args.body), params: trimData(args.params) },
    api,
    extraOptions
  );
  const { access_token, refresh_token }: AuthProps = (
    api.getState() as RootState
  ).auth;

  if (result.error && result.error.status) {
    switch (result.error.status) {
      case 400:
        console.log("400");
        break;
      case 401:
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            if (access_token && refresh_token) {
              const refreshResult: any = await baseQueryNotAuth(
                {
                  url: API_PATH.REFRESH_PATH,
                  method: "POST",
                  body: { refreshToken: refresh_token }
                },
                api,
                extraOptions
              );

              if (refreshResult?.data) {
                saveAccessToken(refreshResult.data?.token);
                saveRefreshToken(refreshResult.data?.refreshToken);

                // reset access_token in redux
                await api.dispatch(setCredentials(refreshResult.data));

                // retry the initial queryresult = await baseQuery(args, api, extraOptions)
                result = await baseQuery(args, api, extraOptions);
              } else {
                api.dispatch(logout());
                localStorage.clear();
                toast.error(result.error.data.message);
              }
            }
          } catch (err) {
            api.dispatch(logout());
            localStorage.clear();
            toast.error(AppTranslation("loginExpired"));
          } finally {
            // release must be called once the mutex should be released again.
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await baseQuery(args, api, extraOptions);
        }
        break;
      case 403:
        console.log(result.error);
        break;
      case 422:
        // eslint-disable-next-line no-case-declarations
        const getError = Object.values(
          (result.error?.data as ErrorProps)?.errors
        )[0];
        toast.error(getError[0]);
        break;
      case 500:
        console.log("50x");
        break;
      case 501:
      case 502:
      case 503:
        toast.error("Internal Server Error");
        break;
    }
  }
  return result;
};
export default baseQueryWithReauth;
