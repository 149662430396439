import HomePage from "@pages/home_page/HomePage";
import { ROUTE_PATH } from "@utils/constants";
import { aiMatchingRoute } from "./aiMatchingRouter";
import { authRoute } from "./authRoute";

export interface RouteConfigProps {
  id: string;
  name: string;
  icon?: JSX.Element;
  activeIcon?: JSX.Element;
  path: string;
  lastPath?: string;
  parent?: string;
  component: () => JSX.Element;
  isProtected: boolean;
  isMenu: boolean;
  permission?: string[];
}

export const routeDefault: RouteConfigProps[] = [
  {
    id: "HomePage",
    name: "Trang chủ",
    // name: i18next.t("homePage"),
    path: ROUTE_PATH.ROOT,
    lastPath: "/",
    component: HomePage,
    isProtected: false,
    isMenu: false
  }
];

export const routeConfig = [...routeDefault, ...aiMatchingRoute];

export const routeMenu = [...authRoute];
