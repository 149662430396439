/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";

import { useAppSelector } from "@apps/hooks";
import { imgLogo } from "@assets/images";
import { CandidateType, MainTab } from "@globalTypes/globalTypes";
import ListJobPage from "@pages/result_analyst/ListJobPage";
import ResultAnalystPage from "@pages/result_analyst/ResultAnalystPage";
import SelectCvPage from "@pages/select_cv/SelectCvPage";
import { isMobie } from "@utils/constants";
import { pdfjs } from "react-pdf";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { MainContainer } from "./styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PrivateRouteProps {
  children: JSX.Element;
}

interface ValueContext {
  currentStep: number;
  onChangeCurrentStep: React.Dispatch<React.SetStateAction<MainTab>>;
  cvPath: string;
  onChangeCvPath: React.Dispatch<React.SetStateAction<string>>;
  candidate: CandidateType | undefined;
  onChangeCandidate: (candidate: CandidateType | undefined) => void;
  handleMatchingCv: () => Promise<void>;
  currentJobMatching: any;
  onChangeCurrentJobMatching: (jobMatching?: any) => void;
}
export const PageContext = React.createContext<Partial<ValueContext>>({});

function PrivateRoute({}: PrivateRouteProps) {
  const { access_token } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(MainTab.SELECT_CV);
  const [cvPath, onChangeCvPath] = useState("");
  const [candidate, setCandidate] = useState<CandidateType | undefined>();
  const [currentJobMatching, setCurrentJobMatching] = useState<any>();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const onChangeTab = (index: number) => () => {
    if (index === MainTab.SELECT_EVALUATE && !currentJobMatching) {
      return;
    }
    setSelectedTab(index);
  };

  const renderTab = (item: string, index: number) => {
    return (
      <div
        className="flex flex-row"
        onClick={
          index !== 0 && selectedTab !== 0 ? onChangeTab(index) : undefined
        }
        key={item}
      >
        <div
          className={`px-[22px] md:px-8 xl:px-[51px] py-[10px] text-[13px]/5 flex flex-row gap-[8px] text-greyED font-medium ${selectedTab === index ? "bg-grey35" : "bg-base"} ${index === 0 && "rounded-bl-[20px] rounded-tl-[20px]"} ${index === 2 && "rounded-tr-[20px] rounded-br-[20px]"}`}
        >
          {item}{" "}
          <span className="border w-5 h-5 rounded-md border-black3C block text-center text-[12px]/[20px] text-greyAD bg-grey2E font-medium">
            {index + 1}
          </span>
        </div>
        {index !== 2 && (
          <div className="w-[1.5px] h-full bg-black3C right-1"></div>
        )}
      </div>
    );
  };

  const contextValues = useMemo(() => {
    return {
      currentStep: selectedTab,
      onChangeCurrentStep: setSelectedTab,
      cvPath,
      onChangeCvPath,
      candidate,
      onChangeCandidate: setCandidate,
      currentJobMatching,
      onChangeCurrentJobMatching: setCurrentJobMatching
    };
  }, [candidate, currentJobMatching, cvPath, selectedTab]);

  const renderChildren = useMemo(() => {
    if (selectedTab === MainTab.SELECT_CV) {
      return <SelectCvPage />;
    }
    if (selectedTab === MainTab.SELECT_JOB) {
      return <ResultAnalystPage />;
    }
    return <ListJobPage />;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 0) {
      setCurrentJobMatching(undefined);
      setCandidate(undefined);
    }
  }, [selectedTab]);

  if (!access_token && !params?.jobId) {
    return <Navigate to={"/"} state={{ from: pathname }} replace />;
  }

  const tabs = isMobie
    ? ["Tải CV", "Kết quả", "Việc làm"]
    : ["Tải lên tệp CV", "Kết quả phân tích", "Việc làm phù hợp"];
  return (
    <MainContainer className="main_content w-screen h-dvh">
      <div className="flex flex-col items-center pt-8 md:pt-[140px] xl:pt-[120px] px-4 md:px-[80px] xl:px-[132px] pb-[60px] cursor-pointer overflow-x-hidden w-screen h-dvh">
        <img src={imgLogo} className="w-[141px] sm:w-[182px] object-contain" />
        <div className="flex flex-row rounded-[20px] my-6 md:mb-0 md:mt-[86px] xl:mt-[18px]">
          {tabs.map(renderTab)}
        </div>
        <PageContext.Provider value={contextValues}>
          {renderChildren}
        </PageContext.Provider>
      </div>
    </MainContainer>
  );
}

export default PrivateRoute;
