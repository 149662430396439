import { useGetUserFileQuery } from "@apps/services/storageService";
import { useEffect, useState } from "react";

export const useGetFile = (key?: string) => {
  const { data } = useGetUserFileQuery({ key }, { skip: !key });
  const [res, setRes] = useState<[string?, string?]>([undefined, undefined]);

  useEffect(() => {
    setRes([undefined, undefined]);
  }, [key]);

  useEffect(() => {
    setRes([data?.src, data?.name]);
  }, [data]);

  return res;
};
