import { imgScanningCv } from "@assets/images";
import { icInfo } from "@assets/svg";
import { Tooltip } from "antd";
import React from "react";
import { ModalRef } from "../ListCVTemplate";
export const queueInfoRef = React.createRef<ModalRef>();
function QueueInfo({ numberOfQueue }: { numberOfQueue: number }) {
  return (
    <div className="flex flex-col items-center sm:w-[608px] w-full rounded-lg bg-gray1D/[0.5] py-6 border border-grey3E/[0.4] shadow-tab mt-14">
      <img src={imgScanningCv} className="w-[368px]" />
      <div className="flex flex-row text-greyED text-xs sm:text-sm/6 font-medium mt-10 text-center">
        CV của bạn đã được upload lên hệ thống iVIEC AI.
        <Tooltip
          placement="top"
          rootClassName="queue-info"
          arrow={false}
          overlayClassName="mb_overlay_tooltip"
          title={
            <div className="flex flex-col">
              <div className="flex flex-row gap-1 text-xs/5 text-greyAD">
                <span>•</span>
                Khi hệ thống có nhiều người dùng cùng trải nghiệm đồng thời, có
                thể CV của bạn phải xếp vào hàng đợi để được hiện.
              </div>
              <div className="flex flex-row gap-1 text-xs/5 text-greyAD">
                <span>•</span>Bạn có thể thực hiện các thao tác khác, chúng tôi
                cũng sẽ gửi kết quả vào email của bạn.
              </div>
            </div>
          }
        >
          <img src={icInfo} className="ml-1" />
        </Tooltip>
      </div>
      <div className="text-greyED text-xs sm:text-sm/6 font-medium text-center">
        Hiện tại có <span className="text-blue60">{numberOfQueue} hồ sơ </span>
        đang chờ xử lý, vui lòng đợi đến bước tiếp theo.
      </div>
      {/* <div
        onClick={onHideQueue}
        className="text-[13px]/[20px] font-medium gap-[6px] flex flex-row items-center py-[6px] px-[26px] rounded-md cursor-pointer dark-linear-gradient text-greyED shadow-button mt-4"
      >
        <img src={icPlus} />
        Tải thêm CV
      </div> */}
    </div>
  );
}
export default QueueInfo;
