import { icDocumentCv } from "@assets/svg";
import { PageContext } from "@components/private_route/PrivateRoute";
import { JobMatching } from "@globalTypes/globalTypes";
import { SelectCvContainer } from "@pages/select_cv/styles";
import { Button, Col, Row, Spin } from "antd";
import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ItemJobSugesstions } from "./ItemJobSugesstions";

function ListJobSugesstions({
  currentJobMatching,
  data,
  triggerRef,
  onShowDetailJob
}: {
  currentJobMatching: JobMatching[];
  data: JobMatching[];
  triggerRef: React.MutableRefObject<null>;
  onShowDetailJob: (item: JobMatching) => void;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const jobId = params?.jobId;
  const { onChangeCurrentStep } = useContext(PageContext);

  const onBack = () => {
    if (params?.jobId) {
      navigate("/");
      return;
    }
    onChangeCurrentStep?.(0);
  };
  return (
    <SelectCvContainer className="flex flex-col items-center justify-between flex-1">
      <Row
        gutter={{ md: 16 }}
        className="flex-col items-center w-full flex-1 lg:w-2/5"
      >
        <div className="title text-[24px]/[32px] sm:text-[40px]/[48px] xl:text-5xl/[72px] font-semibold tracking-[-0.48px] bg-clip-text md:mt-[78px] xl:mt-[70px]">
          Việc làm phù hợp
        </div>
        <div className="description text-white text-center text-2xl font-medium mt-2 text-[13px]/[21px] md:text-[18px]/[32px]">
          Dựa trên dữ liệu đã phân tích từ CV, AI đưa ra đánh giá về mức độ phù
          hợp của CV với các yêu cầu tuyển dụng dưới đây:
        </div>
      </Row>
      <Row
        gutter={[14, { xs: 14, sm: 20, md: 32, lg: 32, xl: 32 }]}
        className="lg:w-4/5 mt-[24px] md:mt-[64px]"
      >
        {Array.isArray(data) &&
          data?.map((item: JobMatching) => (
            <Col
              key={item.id}
              className="animate__animated animate__fadeInUp"
              xs={24}
              sm={12}
              lg={8}
            >
              <ItemJobSugesstions
                onShowModal={() => onShowDetailJob(item)}
                items={item}
              />
            </Col>
          ))}
      </Row>
      {data?.length !== currentJobMatching?.length && (
        <div ref={triggerRef} className="mt-4">
          <Spin />
        </div>
      )}

      <Button
        type="primary"
        className="btn-footer flex items-center mt-10"
        onClick={onBack}
      >
        <img src={icDocumentCv} className="mr-2" />
        {jobId ? "Trang chủ" : "Thử với CV khác"}
      </Button>
    </SelectCvContainer>
  );
}
export default React.memo(ListJobSugesstions);
