/* eslint-disable @typescript-eslint/no-explicit-any */
import { SalaryShowTypeContract } from "@globalTypes/enum";
import { TIME_FORMAT } from "@utils/constants";
import { formatDate, roundNumber } from "@utils/helperFuntions";
import { getImageUrl } from "@utils/stringFormat";
import { Avatar, Col, Progress, Row, Typography } from "antd";

const { Text } = Typography;

type ItemJobSugesstionsType = {
  onShowModal: () => void;
  items: any;
  image?: any;
};

export const ItemJobSugesstions = ({
  onShowModal,
  items
  // image
}: ItemJobSugesstionsType) => {
  return (
    <Row
      className={`SCItemJobSuggestions border border-grey3E rounded-lg shadow-tab h-full p-3 md:p-4 flex-col grow`}
      onClick={() => onShowModal()}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Avatar
            size={49}
            src={getImageUrl(items?.recruitment?.organization?.avatar)}
            className="avatar rounded-lg p-[4px]"
          />
        </Col>
        <Col>
          <Row align={"middle"}>
            <Progress
              type="circle"
              percent={roundNumber(items?.score)}
              size={24}
              strokeColor={{
                "0%": "#5CF101",
                "75%": "#01F1E3"
              }}
            />
            <Text className="text-[16px] font-semibold text-green10 ml-1">
              {roundNumber(items?.score)}% Phù hợp
            </Text>
          </Row>
        </Col>
      </Row>
      <div className="mb-auto">
        <Typography.Paragraph
          ellipsis={{ rows: 2 }}
          className="text-paragraph text-[16px] text-white font-medium mt-3 md:mt-4"
        >
          {items?.recruitment?.name}
        </Typography.Paragraph>
      </div>

      <Typography className="text-greyAD mt-1 md:mb-4 text-[12px] md:text-[16px]">
        {items?.recruitment?.organization?.name}
      </Typography>
      <Typography className="text-greyAD mb-3">
        Hạn ứng tuyển:{" "}
        {formatDate(
          items?.recruitment?.recruitmentPublish?.endTime,
          TIME_FORMAT.DD_MM_YYYY
        )}
      </Typography>
      <Row className="px-[6px] pb-[2px] text-[12px] font-medium text-green10 rounded-[6px] border border-green06 bg-green02 w-fit">
        {items?.recruitment?.salaryShowType ===
          SalaryShowTypeContract.SalaryDetails && (
          <>
            <p className="text-[13px] lg:text-[13px] md:text-[13px] sm:text-[13px]">
              {items?.recruitment?.minSalary?.toLocaleString("vi-VN")} -{" "}
              {items?.recruitment?.maxSalary?.toLocaleString("vi-VN")}{" "}
              {items?.recruitment?.currentUnitType === 1 ? "USD" : "VNĐ"}
            </p>
            <span className="fs-13 fw-500">/tháng</span>
          </>
        )}

        {items?.recruitment?.salaryShowType === SalaryShowTypeContract.Max && (
          <>
            <p className="text-[13px] lg:text-[13px] md:text-[13px] sm:text-[13px]">
              {items?.recruitment?.maxSalary?.toLocaleString("vi-VN")}{" "}
              {items?.recruitment?.currentUnitType === 1 ? "USD" : "VNĐ"}
            </p>
            <span className="fs-13 fw-500">/tháng</span>
          </>
        )}

        {items?.recruitment?.salaryShowType ===
          SalaryShowTypeContract.Minimum && (
          <>
            <p className="text-[13px] lg:text-[13px] md:text-[13px] sm:text-[13px]">
              {items?.recruitment?.maxSalary?.toLocaleString("vi-VN")}{" "}
              {items?.recruitment?.currentUnitType === 1 ? "USD" : "VNĐ"}
            </p>
            <span className="fs-13 fw-500">/tháng</span>
          </>
        )}

        {items?.recruitment?.salaryShowType ===
          SalaryShowTypeContract.Agreement && (
          <p className="text-[13px] lg:text-[13px] md:text-[13px] sm:text-[13px]">
            Thỏa thuận
          </p>
        )}
        {items?.recruitment?.salaryShowType ===
          SalaryShowTypeContract.NoSalary && (
          <p className="text-[13px] lg:text-[13px] md:text-[13px] sm:text-[13px]">
            Không hưởng lương
          </p>
        )}
      </Row>
    </Row>
  );
};
