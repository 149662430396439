import React from "react";

function RelateKeyword({ data }: { data?: string[] }) {
  return (
    <>
      <div className="flex flex-row gap-[13px] self-start w-full flex-wrap">
        {Array.isArray(data) &&
          data.map((item, index) => (
            <div
              className="px-1 text-xs rounded-md border border-black3C bg-grey2E text-greyAD"
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
    </>
  );
}
export default React.memo(RelateKeyword);
