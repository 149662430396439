import restBaseQueryWithReauth from "@apps/config/baseQueryWithReauth";
import { createApi } from "@reduxjs/toolkit/query/react";
import { API_PATH } from "@utils/constants";

export type LoginResposeType = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export const authServiceApi = createApi({
  reducerPath: "authServiceApi",
  baseQuery: restBaseQueryWithReauth,
  keepUnusedDataFor: 0,
  tagTypes: ["userProfile"],
  endpoints: (builder) => ({
    authLogin: builder.mutation({
      query: (loginInput) => ({
        url: API_PATH.LOGIN_PATH,
        method: "POST",
        body: loginInput
      })
    }),
    getUserIdentity: builder.query({
      query: () => ({
        url: API_PATH.PROFILE_PATH,
        method: "GET"
      }),
      providesTags: ["userProfile"]
    }),
    socialLogin: builder.mutation({
      query: (body) => ({
        url: API_PATH.SOCIAL_LOGIN,
        method: "POST",
        body
      })
    })
  })
});

export const {
  useAuthLoginMutation,
  useGetUserIdentityQuery,
  useSocialLoginMutation
} = authServiceApi;
