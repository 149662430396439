"use client";
import { useGetJsonFileQuery } from "@apps/services/storageService";
import useBoolean from "@hooks/useBoolean";
import { Button, UploadFile } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import ItemTemplate from "./ItemTemplate";
import { ModalTemplateWrapper } from "./styles";

export interface ModalRef {
  onShow: () => void;
  onHide: () => void;
}
const ListCVTemplate = forwardRef(
  (
    {
      onScanCv
    }: {
      onScanCv: (value: UploadFile | UploadFile[] | undefined) => Promise<void>;
    },
    ref
  ) => {
    const [isShow, onShow, onHide] = useBoolean();

    const { data: listTemplate } = useGetJsonFileQuery({});
    useImperativeHandle(ref, () => ({
      onShow,
      onHide
    }));
    if (!isShow) {
      return <></>;
    }
    return (
      <ModalTemplateWrapper
        className="content"
        open={isShow}
        onCancel={onHide}
        footer={
          <div className="text-center mt-6">
            <Button type="primary" onClick={onHide} className="btn-close">
              Đóng
            </Button>
          </div>
        }
        closable={false}
        mask={false}
        wrapClassName="ct_show_10_cv"
      >
        <div className="template-content">
          {Array.isArray(listTemplate) &&
            listTemplate.map((item) => (
              <ItemTemplate key={item?.Key} item={item} onScanCv={onScanCv} />
            ))}
        </div>
      </ModalTemplateWrapper>
    );
  }
);

export default React.memo(ListCVTemplate);
