import { RcFile } from "antd/es/upload";

export type CommonObjectType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export interface ObjectKeyCustomProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: number | string | any;
}

export type PermissonProps = {
  id: number;
  name: string;
};

export type UserProps = {
  id: number;
  name: string;
  email: string;
  phone: string;
  permissions: PermissonProps[];
};

export type ResponseProps<T> = {
  data: T;
  message: string;
  statusCode: number;
};

export interface ErrorProps {
  message: string;
  errors: {
    [key: string]: string[];
  };
}

export interface PayloadLoginProps {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface ChildrenProps {
  children: JSX.Element;
}

export interface ParentsProps {
  label: string;
  path: string;
}

export interface AppBreadcrumbProps {
  pageName: string;
  parents?: ParentsProps[];
}

export interface PaginationProps {
  page: number;
  total: number | undefined;
  onChangePage: (page: number, pageSize: number) => void;
}

// New Props
export interface UserIdentityProps {
  email: string;
  firstName: string;
  lastName: string | null;
  isActive: boolean;
  id: string;
  substituteImage: string;
  isPhoneNumberVerified: boolean;
  substituteId: number;
  avatar: string;
  phoneNumber: string;
}

export interface DecodeTokenProps {
  permissions: string[];
}

export type ErrorDataType = {
  data: {
    code: string;
    messages: string[];
  };
  status: number;
};

export interface AuthProps extends DecodeTokenProps {
  userProfile: UserIdentityProps | null;
  user?: UserProps | null;
  access_token: string | null;
  refresh_token: string | null;
}

export interface RequestError {
  data: {
    code: string;
    messages: string[];
    errors: [
      {
        errorCode: string;
      }
    ];
  };
}

export type RequestResponseType = {
  data?: {
    code?: string;
    messages?: string[];
  };
  error?: Partial<RequestError>;
};

export interface AntdNotification {
  title: string;
  description: string | React.ReactNode;
  isError?: boolean;
}

export type ApiResponseProps<T> = {
  items: T[];
  currentPageIndex: number;
  totalPage: number;
  totalRecord: number;
};

export interface ObjectKeyProps {
  [key: string]: number | string;
}

export interface RecruitmentRequirementProps {
  name: string;
  fullName: string;
  email: string;
  id: string;
  applicationUserId: string;
  substituteImage: string;
}

export interface RecruimentResponse {
  label: string;
  value: string;
  key?: string;
  email?: string;
  id?: string;
}

export interface ResponseDataProps<T> {
  currentPageIndex: number;
  items: T[];
  totalPage: number;
  totalRecord: number;
  url?: string;
}

export interface CopyTextProps {
  text: string;
  toastMessage: string | JSX.Element;
}

export interface TemplateResponse {
  Template: Template[];
}

export interface Template {
  Key: string;
  Name: string;
  Type?: string;
  Avatar: string;
}

export enum MainTab {
  SELECT_CV,
  SELECT_JOB,
  SELECT_EVALUATE
}

export enum PreviewCvType {
  DETAIL,
  PREVIEW
}

export interface ActivitiesAndProject {
  ProjectName: string;
  Position: string;
  TimePeriod: string;
  Description?: string[] | string;
}

export interface CandidateType {
  PortraitImage: string;
  FullName: string;
  PhoneNumber: string;
  Email: string;
  DateOfBirth: string;
  Experiences: Experience[];
  ActivitiesAndProjects: ActivitiesAndProject[];
  Educations: Education[];
  Achievements: Achievement[];
  HomeTower: string;
  MaritalStatus: string;
  IdentityNumber: string;
  Weight: string;
  Height: string;
  Sex: string;
  CareerObjective: CareerObjective;
  RawApplicantSkills: string[];
  JobSourceId: string;
  YearOfExperience: string;
  ExpectedSalaryFrom: string;
  ExpectedSalaryTo: string;
  ExpectedWorkingAddress: string;
  LivingAddress: string;
  CvPath: RcFile;
  type?: string;
  cvPreview: string;
  name?: string;
}

export interface Experience {
  CompstringName: string;
  CompstringAddress: string;
  Position: string;
  TimePeriod: string;
  Description: string | string[];
  Des: string;
}

export interface Education {
  School: string;
  Major: string;
  Degree: string;
  GPA: string;
  TimePeriod: string;
}

export interface Achievement {
  Certificate: string;
  Issuer: string;
  TimePeriod: string;
  Description: string[];
}

export interface CareerObjective {
  Position: string;
  Objective: string;
}

export interface TemplateResponse {
  Template: Template[];
}

export interface Template {
  Key: string;
  Name: string;
  Type?: string;
  Avatar: string;
}

export interface JobMatching {
  id: string;
  score: number;
  gender: number;
  salary: number;
  skills: number;
  location: number;
  position: number;
  education: number;
  categories: number;
  experience: number;
  recruitment: Recruitment;
}

export interface Recruitment {
  id: string;
  name: string;
  slug: string;
  benefit: string;
  groupId: string;
  request: string;
  creatorId: string;
  groupName: string;
  maxSalary: string;
  minSalary: string;
  updaterId: string;
  viewCount: number;
  genderType: string;
  createdDate: string;
  createdTime: string;
  creatorName: string;
  description: string;
  jobPosition: string;
  requirement: string;
  updatedDate: string;
  updatedTime: string;
  updaterName: string;
  workspaceId: string;
  creatorEmail: string;
  numOfApplied: number;
  organization: Organization;
  substituteId: number;
  updaterEmail: string;
  workspaceName: string;
  organizationId: string;
  salaryShowType: number;
  currentUnitType: string;
  workspaceDomain: string;
  numOfAppliedByCv: number;
  recruitmentState: number;
  workingAddresses: WorkingAddress[];
  recruitmentStatus: number;
  numOfAppliedByForm: number;
  recruitmentMission: RecruitmentMission;
  recruitmentPublish: RecruitmentPublish;
  workExperienceType: number;
  recruitingAddresses: RecruitingAddress[];
  organizationPolicies: string[];
  organizationVerified: boolean;
  recruitmentLanguages: RecruitmentLanguage[];
  recruitmentStatusBiz: number;
  recruitmentDeltaDatas: string;
  workspaceCustomDomain: string;
  organizationVerifiedId: string;
  recruitmentApplyFormId: string;
  recruitmentPublicStatus: number;
  recruitmentJobCategories: RecruitmentJobCategory[];
  recruitmentRequirementId: string;
  recruitmentApprovalStatus: number;
  recruitmentPersonInCharge: RecruitmentPersonInCharge;
  recruitmentRequiredLevels: RecruitmentRequiredLevel[];
  recruitmentWaysOfWorkings: RecruitmentWaysOfWorking[];
  recruitmentRequirementName: string;
  coRecruitmentPersonInCharges: string[];
  recruitmentRequirementNumberPositions: number;
}

export interface Organization {
  name: string;
  avatar: string;
  coverPhoto: string;
}

export interface WorkingAddress {
  id: string;
  address: string;
  districtId: string;
  provinceId: string;
  districtName: string;
  provinceName: string;
}

export interface RecruitmentMission {
  id: string;
  name: string;
}

export interface RecruitmentPublish {
  id: string;
  endTime: string;
  statTime: string;
  isPublished: boolean;
  recruitmentPublishDatas: RecruitmentPublishData[];
}

export interface RecruitmentPublishData {
  id: string;
  endTime?: string;
  statTime?: string;
  isPublished: boolean;
  recruitmentJobSiteStatus?: number;
  recruitmentPublishDataType: number;
  recruitmentCareerSiteStatus: number;
}

export interface RecruitingAddress {
  id: string;
  address: string;
  districtId: string;
  provinceId: string;
  districtName: string;
  provinceName: string;
}

export interface RecruitmentLanguage {
  id: string;
  languageType: number;
}

export interface RecruitmentJobCategory {
  id: string;
  name: string;
  type: string;
  description: string;
}

export interface RecruitmentPersonInCharge {
  id: string;
  personInChargeId: string;
  personInChargeName: string;
  personInChargeEmail: string;
  personInChargeSubstituteImage: string;
}

export interface RecruitmentRequiredLevel {
  id: string;
  requiredLevelType: number;
}

export interface RecruitmentWaysOfWorking {
  id: string;
  waysOfWorkingType: number;
}
