import imgLoading from "@assets/images/loading.gif";
import { Template } from "@globalTypes/globalTypes";
import { getImageUrl } from "@utils/stringFormat";
import { UploadFile } from "antd";
import React, { useState } from "react";

function ItemTemplate({
  item,
  onScanCv
}: {
  item: Template;
  onScanCv: (value: UploadFile | UploadFile[] | undefined) => Promise<void>;
}) {
  const onSelectTemplate = () =>
    onScanCv({
      response: item?.Key,
      uid: item?.Key,
      name: item?.Avatar
    });
  const srcCv = getImageUrl(`${item?.Avatar}&bucket=iviec-dev-storage`);
  const [isHide, setIsHide] = useState(true);

  setTimeout(() => setIsHide(false), 500);

  return (
    <div className="choose-cv" onClick={onSelectTemplate}>
      <div className="cv-item">
        {isHide ? (
          <img src={imgLoading} className="cv-thumbnail template-loading" />
        ) : (
          <img className="cv-thumbnail" alt="" src={srcCv} />
        )}
        <img className="bg-gradient" />
      </div>
    </div>
  );
}

export default React.memo(ItemTemplate);
