import { configureStore } from "@reduxjs/toolkit";
import { authServiceApi } from "./services/authService";
import { storageServiceApi } from "./services/storageService";
import authSliceReducer from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [storageServiceApi.reducerPath]: storageServiceApi.reducer,
    auth: authSliceReducer
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authServiceApi.middleware,
      storageServiceApi.middleware
    ])
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
