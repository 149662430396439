import {
  imgBgSelectCv,
  imgPrivateMobieBg,
  imgPrivateTabletBg
} from "@assets/images";
import styled from "styled-components";

export const MainContainer = styled.main`
  background: linear-gradient(
    99.05deg,
    rgba(0, 0, 0, 0) -2.13%,
    rgba(0, 0, 0, 0.67) 39.63%,
    rgba(0, 0, 0, 0.46) 71.2%,
    #000000 102.03%
  );
  background-image: url(${imgBgSelectCv}) !important;
  background-size: cover;
  .tab {
    padding: 10px 51px;
  }
  .tab:not(:last-child) {
    border-right: 1px solid var(--Contrast-contrast-border-base, #3c3f44);
  }
  @media (max-width: 640px) {
    background-image: url(${imgPrivateMobieBg}) !important;
  }
  @media (max-width: 1024px) {
    background-image: url(${imgPrivateTabletBg});
  }
`;
