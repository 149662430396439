import restBaseQueryWithReauth from "@apps/config/baseQueryWithReauth";
import {
  CandidateType,
  JobMatching,
  TemplateResponse
} from "@globalTypes/globalTypes";
import { createApi } from "@reduxjs/toolkit/query/react";
import { API_PATH } from "@utils/constants";

type GetFileType = {
  src: string;
  name: string;
};

export const storageServiceApi = createApi({
  reducerPath: "storageServiceApi",
  tagTypes: ["scanTimes"],
  baseQuery: restBaseQueryWithReauth,
  endpoints: (builder) => ({
    getUserFile: builder.query({
      query: ({ url = API_PATH.GET_USER_FILE, ...params }) => ({
        url,
        method: "GET",
        params,
        responseHandler: async (res) => {
          const blob = await res.blob();
          return {
            src: URL.createObjectURL(blob),
            name: decodeURI(res.headers.get("X-Original-File-Name") || "")
          };
        }
      }),
      transformResponse: (r) => r as GetFileType
    }),
    getJsonFile: builder.query({
      query: ({ url = API_PATH.GET_TEMPLATES_CV }) => ({
        url,
        method: "GET"
      }),
      transformResponse: (response: TemplateResponse) => {
        return response?.Template;
      }
    }),
    scanUserCv: builder.query({
      query: ({ url = API_PATH.SCAN_CV, ...params }) => ({
        url,
        method: "POST",
        body: params?.formData,
        formData: !params?.cvPath
      })
    }),
    scanCvTemplates: builder.query({
      query: ({ url = API_PATH.SCAN_CV_TEMPLATES, ...params }) => ({
        url,
        method: "POST",
        body: params
      })
    }),
    jobMatchingTemplates: builder.query({
      query: (payload) => ({
        url: API_PATH.JOB_MATCHING_TEMPLATES,
        method: "POST",
        body: payload
      }),
      keepUnusedDataFor: 0
    }),
    jobMatchingAI: builder.query({
      query: (payload) => ({
        url: API_PATH.JOB_MATCHING,
        method: "POST",
        body: payload?.formData,
        formData: true
      }),
      keepUnusedDataFor: 0
    }),
    getCurrentScanTimes: builder.query({
      query: ({ url = API_PATH.SCAN_TIME, ...params }) => ({
        url,
        method: "GET",
        params
      }),
      providesTags: ["scanTimes"]
    }),
    getJobScanStatus: builder.query<
      {
        data: {
          scanData: CandidateType;
          matchData: JobMatching[];
        };
        jobCount: { waiting: number };
        result: boolean;
      },
      { jobId: string }
    >({
      query: (params) => ({
        url: `${API_PATH.JOB_STATUS}/${params?.jobId}`,
        method: "GET"
      })
    }),
    getJobResult: builder.query({
      query: (params) => ({
        url: `${API_PATH.JOB_RESULT}/${params?.jobId}`,
        method: "GET"
      })
    })
  })
});

export const {
  useGetUserFileQuery,
  useLazyGetUserFileQuery,
  useLazyScanUserCvQuery,
  useScanCvTemplatesQuery,
  useLazyScanCvTemplatesQuery,
  useJobMatchingTemplatesQuery,
  useLazyJobMatchingTemplatesQuery,
  useGetJsonFileQuery,
  useLazyJobMatchingAIQuery,
  useGetCurrentScanTimesQuery,
  useLazyGetCurrentScanTimesQuery,
  useLazyGetJobScanStatusQuery,
  useGetJobResultQuery
} = storageServiceApi;
