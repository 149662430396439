import { icArrowUp } from "@assets/svg";
import React from "react";
import Divider from "./Divider";
function BtnViewLess({ onCollapse }: { onCollapse: () => void }) {
  return (
    <>
      <Divider />
      <div className="btn-collapse" onClick={onCollapse}>
        Thu gọn
        <img src={icArrowUp} />
      </div>
    </>
  );
}

export default React.memo(BtnViewLess);
