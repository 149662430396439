/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CommonObjectType,
  CopyTextProps,
  RequestError,
  RequestResponseType
} from "@globalTypes/globalTypes";
import dayjs from "dayjs";
import i18next from "i18next";
import { toast } from "react-toastify";
import { ERROR_CODE_CONVERT, TIME_FORMAT } from "./constants";
export function emptyFunction() {
  return;
}

export const showErrorMessage = (error: unknown) => {
  const err = error as RequestError;

  const message = err?.data?.messages?.length > 0 ? err?.data?.messages[0] : "";
  toast(
    ERROR_CODE_CONVERT[err?.data?.code] ||
      message ||
      i18next.t("somethingWentWrong"),
    {
      position: "top-right",
      type: "error"
    }
  );
};

export const checkIsErrorCode = (error: unknown, code: string) => {
  return (error as RequestResponseType)?.data?.code === code;
};

export const showSuccessMessage = (message: string | JSX.Element) => {
  toast(message, {
    position: "top-right",
    type: "success"
  });
};

export const formatDate = (
  date?: string,
  format = TIME_FORMAT.DD_MM_YYY_HH_MM_SS
) => {
  if (!date) return "";

  return dayjs(date).format(format);
};

export const formatDateToDayofWeek = (
  date?: string,
  format = TIME_FORMAT.DD_MM_YYY_HH_MM_SS
) => {
  if (!date) return "";
  const currentDate = dayjs(date);
  const currentDay = currentDate.day() + 1;
  return currentDay === 1
    ? i18next.t("sunday", {
        date: currentDate.format(format)
      })
    : i18next.t("fullDate", {
        dayOfWeek: currentDay,
        date: currentDate.format(format)
      });
};

export const diffDateString = (startDate: string, endDate: string) => {
  const dStartDate = dayjs(startDate);
  const dEndDate = dayjs(endDate);
  let monthDiff = dEndDate.diff(dStartDate, "months");
  monthDiff = monthDiff < 1 ? 1 : monthDiff;
  const diffYear = Math.floor(monthDiff / 12);
  if (diffYear < 1) {
    return `${monthDiff} ${i18next.t("month")}`;
  }
  const diffMonth = Math.ceil(monthDiff % 12);
  const monthString =
    diffMonth !== 0 ? `${diffMonth} ${i18next.t("month")}` : "";
  return `${diffYear} ${i18next.t("year")} ${monthString}`;
};

export const copyText = async ({ text, toastMessage }: CopyTextProps) => {
  await navigator.clipboard.writeText(text || "");

  toast.success(toastMessage);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareObject = (a: any, b: any) => {
  if (typeof a !== typeof b) return false;

  if (typeof a === "function") return false;

  if (a && b && typeof a === "object") {
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;

      for (let i = 0; i < a.length; i++) {
        if (!compareObject(a[i], b[i])) return false;
      }

      return true;
    }

    if (Array.isArray(a) || Array.isArray(b)) return false;

    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (const key in a) {
      if (!compareObject(a[key], b[key])) return false;
    }

    return true;
  }

  return a === b;
};

export const removeEmptyField = (
  obj: CommonObjectType,
  isAllowEmptyString = false
) => {
  const newObj: CommonObjectType = {};
  for (const key in obj) {
    const isNotEmptyString = obj[key] !== "" || isAllowEmptyString;
    if (obj[key] !== undefined && obj[key] !== null && isNotEmptyString) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const convertMbToGb = (el: number) => {
  if (el === 0) {
    return el;
  } else {
    return (el / 1024 / 1024 / 1024).toFixed(3);
  }
};

export const formatMoney = (el: number) => {
  const numString = el.toString();

  // Sử dụng regex để thêm dấu phẩy giữa mỗi nhóm ba chữ số từ phải sang trái
  const formattedString = numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedString;
};

export const checkIsVideo = (fileName: string) => {
  if (!fileName || typeof fileName !== "string") return false;

  const videoExtension = ["mp4", "avi", "mov", "wmv", "flv", "3gp"];
  const extension = fileName.split(".").pop();
  return videoExtension.includes(extension || "");
};
export const roundNumber = (number: number) => {
  return number === undefined ? 100 : Math.round((number || 0) * 100);
};

export const fixedNumber = (number: number) => {
  if (number === undefined) {
    return "100.00";
  }
  return ((number || 0) * 100).toFixed(2);
};
