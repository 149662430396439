import { icRemove, icTrash, icUpload } from "@assets/svg";
import { UploadWrapperChildProps } from "@components/UploadWrapper";
import { modalLoginSocialRef } from "@pages/home_page/HomePage";
import ModalLoginSocial from "@pages/home_page/components/ModalLoginSocial";
import { MAX_SCAN_TIMES } from "@utils/constants";
import { formatFileSize } from "@utils/stringFormat";
import { Spin } from "antd";
import React, { MouseEvent, useMemo } from "react";
import { Document, Page } from "react-pdf";
import { UploadCvContainter } from "../styles";

function UploadCvContent({
  data,
  isUploaded,
  isUploading,
  isFileTooLarge,
  scanTimes,
  handleDelete
}: UploadWrapperChildProps) {
  const renderDesciption = useMemo(() => {
    if (isUploading) {
      return "Đang tải lên...";
    }
    if (isUploaded || data?.response) {
      return `${formatFileSize(data?.size)}`;
    }
    if (isFileTooLarge) return "Dung lượng tệp quá lớn.";
    return "";
  }, [data?.response, data?.size, isFileTooLarge, isUploaded, isUploading]);

  const onRemove = (e: MouseEvent<HTMLImageElement>) => {
    e?.stopPropagation();
    handleDelete?.();
  };
  const onShowModalLogin = () => {
    if (scanTimes && scanTimes >= MAX_SCAN_TIMES) {
      modalLoginSocialRef.current?.onShow({ scanTimes });
    }
  };
  return (
    <>
      <UploadCvContainter
        className="w-full md:h-full py-8 sm:py-[58px] flex flex-col items-center justify-center gap-2 bg-grey1D/[0.8]"
        onClick={onShowModalLogin}
      >
        <div className="flex flex-row items-center text-[13px]/5 font-medium gap-2 text-greyAD">
          <img src={icUpload} className="w-5 h-5" />
          Tải lên tệp PDF
        </div>
        <div className="text-[13px]/5 text-black69">Dung lượng tối đa 20MB</div>
      </UploadCvContainter>
      {(isUploaded || isUploading || data?.originFileObj || isFileTooLarge) && (
        <div className="rounded-lg border border-grey2E py-2 px-3 flex flex-row gap-3 items-center mt-4 bg-black27">
          <div className="w-8 h-8 rounded-md border border-grey2E border-none bg-white overflow-hidden">
            <Document
              file={
                isFileTooLarge
                  ? undefined
                  : URL.createObjectURL(data?.originFileObj as Blob)
              }
              noData={<></>}
              loading={<Spin />}
            >
              <Page pageNumber={1} scale={0.1} />
            </Document>
          </div>
          <div className="flex flex-col flex-1 ">
            <span className="text-[13px]/[20px] text-greyED ">
              {data?.name || ""}
            </span>
            <span
              className={`text-xs/[20px] ${data?.response && "text-white"} ${isUploading && "text-blue60"} ${isFileTooLarge && "text-redFB"}`}
            >
              {renderDesciption}
            </span>
          </div>
          <img
            onClick={onRemove}
            src={isUploaded || data?.response ? icTrash : icRemove}
            className="w-5 h-5 cursor-pointer"
          />
        </div>
      )}
      <ModalLoginSocial ref={modalLoginSocialRef} />
    </>
  );
}
export default React.memo(UploadCvContent);
