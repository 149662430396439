import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./en/translation.json";
import translationVN from "./vn/translation.json";

const DEFAULT_LANGUAGE: string = process.env.REACT_APP_DEFAULT_LANGUAGE ?? "vn";

const resources = {
  en: {
    translation: translationEN
  },
  vn: {
    translation: translationVN
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: i18n.language || window.localStorage.i18nextLng || DEFAULT_LANGUAGE,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: "vn",
    debug: false,
    returnNull: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
