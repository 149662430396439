/* eslint-disable @typescript-eslint/no-explicit-any */
import { imgJob } from "@assets/images";
import { icBuilding, icPlace, icSalary } from "@assets/svg";
import { SalaryShowTypeContract } from "@globalTypes/enum";
import { formatMoney } from "@utils/helperFuntions";
import React, { useMemo } from "react";
import InfoItem from "./InfoItem";

function JobInfo({ dataJobSuggestion }: { dataJobSuggestion: any }) {
  const workingAddress = dataJobSuggestion?.recruitment?.recruitingAddresses;
  const fristWorkingAddress =
    workingAddress?.length > 0 ? workingAddress[0] : undefined;
  const renderSalary = useMemo(() => {
    const unit =
      dataJobSuggestion?.recruitment?.currentUnitType === 1
        ? "USD/tháng"
        : "VNĐ/tháng";
    const minSalary = formatMoney(
      dataJobSuggestion?.recruitment?.minSalary || ""
    );
    const maxSalary = formatMoney(
      dataJobSuggestion?.recruitment?.maxSalary || ""
    );
    const salarys = {
      [SalaryShowTypeContract.SalaryDetails]: `${minSalary} - ${maxSalary} ${unit}`,
      [SalaryShowTypeContract.Max]: `${maxSalary} ${unit}`,
      [SalaryShowTypeContract.Minimum]: `${minSalary} ${unit}`,
      [SalaryShowTypeContract.Agreement]: "Thỏa thuận",
      [SalaryShowTypeContract.NoSalary]: "Không hưởng lương"
    };
    return (
      salarys[
        dataJobSuggestion?.recruitment?.salaryShowType as keyof typeof salarys
      ] || ""
    );
  }, [dataJobSuggestion?.recruitment]);
  return (
    <div className="flex flex-col items-center pt-6 xl:p-6 w-full xl:w-[50%]">
      <img src={imgJob} className="w-20 h-20"></img>
      <div className="flex flex-col gap-4 w-full px-6 xl:px-0">
        <div className="text-[18px]/[28px] font-bold text-greyED mt-12">
          {dataJobSuggestion?.recruitment?.name || ""}
        </div>
        <div className="flex row items-center gap-2 text-[13px] font-medium text-greyAD">
          <img src={icPlace} className="w-[22px] h-[22px]" />
          {dataJobSuggestion?.recruitment?.organization?.name || ""}
        </div>
        <div className="flex row items-center gap-2 text-[13px] font-medium text-greyAD">
          <img src={icBuilding} className="w-[22px] h-[22px]" />
          {[
            fristWorkingAddress?.address,
            fristWorkingAddress?.districtName,
            fristWorkingAddress?.provinceName
          ]
            .filter((item) => !!item)
            .join(", ")}
        </div>
        <div className="flex row items-center gap-2">
          <img src={icSalary} className="w-[22px] h-[22px]" />
          <span className="border border-green06 bg-green02 py-[1px] px-[6px] rounded-md text-green10 text-xs font-medium">
            {renderSalary}
          </span>
        </div>
      </div>
      <div className="w-full pt-7 pb-1 xl:py-7">
        <div className="w-full h-[1px] bg-grey2E" />
      </div>
      <InfoItem
        title="Mô tả công việc"
        value={dataJobSuggestion?.recruitment?.description}
      />
      <InfoItem
        title="Yêu cầu ứng viên"
        value={dataJobSuggestion?.recruitment?.requirement}
      />
      <InfoItem
        title="Quyền lợi và chế độ"
        value={dataJobSuggestion?.recruitment?.benefit}
        isHiddenDivider
      />
      {/* <RelateKeyword /> */}
    </div>
  );
}

export default React.memo(JobInfo);
