/* eslint-disable @typescript-eslint/no-explicit-any */
import { imgCard } from "@assets/images";
import {
  listTemplateRef,
  modalLoginSocialRef
} from "@pages/home_page/HomePage";
import { MAX_SCAN_TIMES } from "@utils/constants";
import React from "react";

function SelectCvTemplate({ scanTimes }: { scanTimes: number }) {
  const onViewTemplate = (event: any) => {
    event?.stopPropagation();
    if (scanTimes && scanTimes >= MAX_SCAN_TIMES) {
      modalLoginSocialRef.current?.onShow({ scanTimes });
      return;
    }
    listTemplateRef.current?.onShow();
  };

  return (
    <div
      className="rounded-lg border border-grey35  bg-grey1D/[0.8] flex justify-center h-[112px] sm:h-full overflow-hidden"
      onClick={onViewTemplate}
    >
      <img
        src={imgCard}
        className="md:w-[90%] xl:w-[257px] h-[160px] sm:h-auto"
      />
    </div>
  );
}
export default React.memo(SelectCvTemplate);
