import { imgDot } from "@assets/svg";
import { SelectCvContainer } from "@pages/select_cv/styles";
import styled from "styled-components";

export const ResultAndAnalystContainer = styled(SelectCvContainer)`
  .bg-linear {
    background: linear-gradient(
      180deg,
      rgba(29, 30, 43, 0) 0.06%,
      rgba(29, 30, 43, 0) 50.92%,
      rgba(29, 30, 43, 0.9) 76.2%,
      #1d1e2b 102.57%
    );
  }
  .recruitment-info {
    li,
    span,
    div,
    p {
      font-size: 13px;
      color: #adb1b8 !important;
    }
  }
  .recruitment-info {
    ul {
      gap: 18px;
      display: flex;
      flex-direction: column;
    }
    li {
      padding-left: 20px;
      position: relative;
      span {
        line-height: 20px;
      }
    }
    li:before {
      content: url(${imgDot});
      display: inline-block;
      height: 14px;
      margin-right: 19px;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
`;
