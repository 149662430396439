import { PageContext } from "@components/private_route/PrivateRoute";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { isOtherDevince } from "@utils/constants";
import { getImageUrl } from "@utils/stringFormat";
import React, { useContext } from "react";

function CvPreview() {
  const { candidate } = useContext(PageContext);

  const url =
    typeof candidate?.CvPath === "string"
      ? getImageUrl(`${candidate?.CvPath}&bucket=iviec-dev-storage`)
      : URL.createObjectURL(candidate?.CvPath as Blob);

  return (
    <div className="w-full mt-2 rounded-md h-full overflow-hidden">
      {isOtherDevince && candidate?.CvPath ? (
        <div className="flex grow">
          <Viewer fileUrl={url as string} />
        </div>
      ) : (
        <iframe
          src={`${url}#toolbar=0`}
          className="w-full md:h-screen xl:h-full"
          allowFullScreen
        />
      )}
    </div>
  );
}
export default React.memo(CvPreview);
