import React from "react";

function Divider({ className }: { className?: string }) {
  return (
    <div className={`w-full ${className}`}>
      <div className="w-full h-[1px] bg-grey2E" />
    </div>
  );
}
export default React.memo(Divider);
