import { PageContext } from "@components/private_route/PrivateRoute";
import { JobMatching } from "@globalTypes/globalTypes";
import useBoolean from "@hooks/useBoolean";
import ListJobSugesstions from "@pages/job_mapping/ListJobSugesstions";
import { NUM_PER_PAGE } from "@utils/constants";
import { roundNumber } from "@utils/helperFuntions";
import useLazyLoad from "@utils/useLazyLoad";
import React, { useContext, useMemo, useRef, useState } from "react";
import JobAndAnalyst from "./JobAndAnalyst";

function ListJobPage() {
  const { currentJobMatching } = useContext(PageContext);
  const [isShowDetailJob, onShowDetailJob, onHideDetailJob] = useBoolean(false);
  const [currentJob, setCurrentJob] = useState<JobMatching | undefined>();
  const triggerRef = useRef(null);

  const onGrabData = (currentPage: number, data: JobMatching) => {
    if (data === currentJobMatching?.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = currentJobMatching.slice(
          (currentPage - 1) * NUM_PER_PAGE,
          NUM_PER_PAGE * currentPage
        );
        resolve(data);
      }, 100);
    });
  };
  const { data } = useLazyLoad({ triggerRef, onGrabData });
  const listJob = useMemo(() => {
    return Array.isArray(currentJobMatching)
      ? [...currentJobMatching].sort(
          (currentItem, nextItem) =>
            roundNumber(nextItem?.score) - roundNumber(currentItem?.score)
        )
      : [];
  }, [currentJobMatching]);

  const onSelectJob = (item: JobMatching) => {
    onShowDetailJob();
    setCurrentJob(item);
  };

  return (
    <div className="relative">
      <div className={`${isShowDetailJob && "hidden"}`}>
        <ListJobSugesstions
          currentJobMatching={listJob || []}
          onShowDetailJob={onSelectJob}
          data={data}
          triggerRef={triggerRef}
        />
      </div>
      {isShowDetailJob && (
        <JobAndAnalyst
          onHideDetailJob={onHideDetailJob}
          dataJobSuggestion={currentJob}
        />
      )}
    </div>
  );
}
export default React.memo(ListJobPage);
