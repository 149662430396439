import React from "react";

function Title({ title, className }: { title: string; className?: string }) {
  return (
    <div
      className={`text-[16px] xl:text-[18px]/[28px] font-semibold text-greyED ${className}`}
    >
      {title}
    </div>
  );
}
export default React.memo(Title);
