/* eslint-disable @typescript-eslint/no-explicit-any */

import { imgChartBg, imgChartBorder } from "@assets/images";
import { roundNumber } from "@utils/helperFuntions";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip
} from "chart.js";
import { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const CHART_OPTIONS = {
  elements: {
    line: {
      borderWidth: 3
    },
    point: {
      radius: 0
    }
  },
  color: "red",
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  layout: {
    padding: -100
  },
  scales: {
    r: {
      angleLines: {
        display: true,
        color: "#2E3035"
      },
      grid: {
        color: "#2E3035",
        tickLength: 5,
        drawTicks: false,
        circular: true
      },
      pointLabels: {
        font: {
          family: "Space Mono"
        },
        color: "#EDEEF0"
      },
      ticks: {
        maxTicksLimit: 4,
        display: false
      }
    }
  }
};

const ChartReasonableRate = ({
  data,
  score
}: {
  data: Array<{ text: string; value: number }>;
  score: number;
}) => {
  const [_data, setData] = useState<
    ChartData<"radar", (number | null)[], string>
  >({
    labels: [],
    datasets: []
  });
  useEffect(() => {
    // Load an image
    const img = new Image();
    img.src = imgChartBorder;

    img.onload = () => {
      const ctx = (document?.getElementById("radarChart") as any).getContext(
        "2d"
      );
      const pattern = ctx.createPattern(img, "repeat");
      const labels = data.map((item) => item?.text);
      setData({
        labels: labels || "",
        datasets: [
          {
            label: "",
            data: data?.map((item) => item?.value),
            fill: false,
            backgroundColor: "transparent",
            borderColor: pattern
          }
        ]
      });
    };
  }, [data]);

  return (
    <div className="flex flex-col flex-1 items-center w-full">
      <div className="relative w-full xl:w-[400px] flex justify-center">
        <img
          src={imgChartBg}
          className="absolute w-[65%] h-[65%] top-[20%] left-[15%]"
        />
        <Radar id="radarChart" data={_data} options={CHART_OPTIONS} />
      </div>
      <div className="text-[36px]/[50px] xl:text-[46px]/[50px] font-bold font-space-mono text-greyED">
        {roundNumber(score)}%
      </div>
      <div className="text-base/[20px] text-greyAD font-bold mt-1">
        Tổng mức độ phù hợp
      </div>
    </div>
  );
};

export default ChartReasonableRate;
