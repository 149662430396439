/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import { useAppDispatch, useAppSelector } from "@apps/hooks";
import {
  storageServiceApi,
  useGetCurrentScanTimesQuery,
  useGetJobResultQuery,
  useLazyGetJobScanStatusQuery,
  useLazyScanCvTemplatesQuery,
  useLazyScanUserCvQuery
} from "@apps/services/storageService";
import { imgBgScan, imgTemplate, imgUpload } from "@assets/images";
import { UploadWrapper } from "@components/UploadWrapper";
import { PageContext } from "@components/private_route/PrivateRoute";
import { CandidateType, JobMatching } from "@globalTypes/globalTypes";
import useBoolean from "@hooks/useBoolean";
import {
  listTemplateRef,
  modalLoginSocialRef
} from "@pages/home_page/HomePage";
import ModalLoginSocial from "@pages/home_page/components/ModalLoginSocial";
import ScanningCV from "@pages/select_cv/components/ScanningCV";
import { MAX_SCAN_TIMES, PATH_DOMAIN_UPLOAD_API } from "@utils/constants";
import { showErrorToast } from "@utils/toast";
import { UploadFile } from "antd";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSearchParams } from "react-router-dom";
import ListCVTemplate from "./ListCVTemplate";
import ButtonSelectCv from "./components/ButtonSelectCv";
import Header from "./components/Header";
import QueueInfo from "./components/QueueInfo";
import SelectCvTemplate from "./components/SelectCvTemplate";
import UploadCvContent from "./components/UploadCvContent";
import { SelectCvContainer } from "./styles";

export default function SelectCvPage() {
  const { onChangeCurrentStep, onChangeCandidate, onChangeCurrentJobMatching } =
    useContext(PageContext);
  const [scanCv] = useLazyScanUserCvQuery();
  const [scanCvTemplates] = useLazyScanCvTemplatesQuery();
  const { access_token } = useAppSelector((auth) => auth.auth);
  const [isLoading, onLoading, onHideLoading] = useBoolean(false);
  const [isQueue, onShowQueue, onHideQueue] = useBoolean(false);
  const refTimeOut = useRef<NodeJS.Timeout>();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const jobId = params?.jobId;
  const { currentData } = useGetCurrentScanTimesQuery(
    {},
    { skip: !access_token }
  );

  const { currentData: scanData, isLoading: isLoadingFromEmail } =
    useGetJobResultQuery({ jobId: jobId }, { skip: !jobId });
  const [getScanJobStatus] = useLazyGetJobScanStatusQuery({});
  const dispatch = useAppDispatch();
  const [numberOfQueue, setNumberOfQueue] = useState(0);

  const onChangeDataAndNavigate = useCallback(
    (matchData: any) => {
      onChangeCurrentStep?.(1);
      onChangeCurrentJobMatching?.(matchData);
    },
    [onChangeCurrentJobMatching, onChangeCurrentStep]
  );

  const onError = useCallback(() => {
    showErrorToast();
    onHideLoading();
    onHideQueue();
  }, [onHideLoading, onHideQueue]);

  const onGetDataAndDownload = useCallback(
    async (jobId: string, value: UploadFile | UploadFile[] | undefined) => {
      if (Array.isArray(value)) {
        return;
      }
      let res:
        | { scanData: CandidateType; matchData: JobMatching[] }
        | undefined = undefined;
      const removeSession = () => {
        clearTimeout(refTimeOut.current);
      };
      try {
        const result = await getScanJobStatus({ jobId: jobId }).unwrap();
        const numberOfWaiting = result?.jobCount?.waiting;
        setNumberOfQueue(numberOfWaiting);
        if (numberOfWaiting) {
          onHideLoading();
          onShowQueue();
        }
        res = result?.data || {};
        removeSession();
        if (res && result?.result) {
          onHideQueue();
          onChangeCandidate?.({
            ...res?.scanData,
            CvPath: !value?.type ? value?.response : value?.originFileObj,
            name: value?.name,
            type: value?.type
          });
          onChangeDataAndNavigate(res?.matchData);
        }
      } catch (err) {
        res = { scanData: {} as CandidateType, matchData: [] };
        removeSession();
        onError();
      } finally {
        if (!res?.scanData) {
          refTimeOut.current = setTimeout(() => {
            onGetDataAndDownload(jobId, value);
          }, 2000);
        }
      }
    },
    [
      getScanJobStatus,
      onChangeCandidate,
      onChangeDataAndNavigate,
      onError,
      onHideLoading,
      onHideQueue,
      onShowQueue
    ]
  );

  const onSelectCv = useCallback(
    async (value: UploadFile | UploadFile[] | undefined) => {
      if (!Array.isArray(value) && value?.response) {
        const cvPath = value?.response || value?.originFileObj;
        if (!cvPath) {
          return;
        }
        try {
          const delayPromise = new Promise((resolve) => {
            setTimeout(resolve, 5000);
          });
          listTemplateRef.current?.onHide();
          onLoading();
          let res = [];
          if (value?.type) {
            const formData = new FormData();
            formData.append("file", value?.originFileObj as Blob);
            res = await Promise.all([
              scanCv({
                formData
              }).unwrap(),
              delayPromise
            ]);
          } else {
            res = await Promise.all([
              scanCvTemplates({
                filePath: cvPath
              }).unwrap(),
              delayPromise
            ]);
          }
          onGetDataAndDownload(res[0]?.jobId, value);
        } catch (error) {
          dispatch(storageServiceApi.util.invalidateTags(["scanTimes"]));
          onError();
        }
      }
    },
    [
      dispatch,
      onError,
      onGetDataAndDownload,
      onLoading,
      scanCv,
      scanCvTemplates
    ]
  );

  const renderContent = useMemo(() => {
    if (isQueue && numberOfQueue !== 0) {
      return <QueueInfo numberOfQueue={numberOfQueue} />;
    }
    if ((isLoading && numberOfQueue === 0) || isLoadingFromEmail) {
      return <ScanningCV />;
    }
    return (
      <div className="flex flex-col sm:flex-row mt-[29px] md:mt-[76px] xl:mt-[54px] gap-4 w-full xl:w-auto">
        <ButtonSelectCv
          title="Tải lên CV"
          description="Tải lên CV từ thiết bị của bạn"
          img={imgUpload}
        >
          <UploadWrapper
            disabled={false}
            action={`${PATH_DOMAIN_UPLOAD_API}/storage/Storage/UserUploadFileCv`}
            maxSize={20 * Math.pow(10, 6)}
            accept={"application/pdf"}
            errorMsg={"File sai định dạng. Vui lòng sử dụng file PDF"}
            removeFileNames={[".rar"]}
            onChange={onSelectCv}
            openFileDialogOnClick={currentData?.scanTimes < MAX_SCAN_TIMES}
          >
            <UploadCvContent scanTimes={currentData?.scanTimes} />
          </UploadWrapper>
        </ButtonSelectCv>
        <ButtonSelectCv
          title="Chọn CV mẫu"
          description={"Chọn CV có sẵn trong được gợi ý của iVIEC"}
          img={imgTemplate}
        >
          <SelectCvTemplate scanTimes={currentData?.scanTimes} />
        </ButtonSelectCv>
      </div>
    );
  }, [
    currentData?.scanTimes,
    isLoading,
    isLoadingFromEmail,
    isQueue,
    numberOfQueue,
    onSelectCv
  ]);

  useEffect(() => {
    if (scanData) {
      onChangeCandidate?.({
        ...scanData?.scanData,
        CvPath: scanData?.fileName,
        name: scanData?.fileName,
        type: undefined
      });
      onChangeDataAndNavigate(scanData?.matchData);
    }
  }, [
    onChangeCandidate,
    onChangeCurrentJobMatching,
    onChangeCurrentStep,
    onChangeDataAndNavigate,
    scanData
  ]);

  return (
    <SelectCvContainer className="flex flex-col items-center justify-between flex-1 w-full h-dvh">
      <div className="flex flex-col items-center w-full flex-1">
        <Header />
        {renderContent}
        <ListCVTemplate ref={listTemplateRef} onScanCv={onSelectCv} />
      </div>

      {isLoading && (
        <img
          src={imgBgScan}
          className="w-[44vw] overflow-hidden bottom-0 absolute"
        />
      )}
      <ModalLoginSocial ref={modalLoginSocialRef} />
    </SelectCvContainer>
  );
}
