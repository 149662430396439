import { icChatBuble } from "@assets/svg";
import { PageContext } from "@components/private_route/PrivateRoute";
import useBoolean from "@hooks/useBoolean";
import React, { useContext } from "react";
import BtnViewLess from "./components/BtnViewLess";
import BtnViewMore from "./components/BtnViewMore";
import CVInfo from "./components/CVInfo";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import ResultHeader from "./components/ResultHeader";
import { ResultAndAnalystContainer } from "./styles";

function ResultAnalystPage() {
  const [isCollapse, onCollapse, onHideCollapse] = useBoolean(true);
  const { onChangeCurrentStep } = useContext(PageContext);

  const onViewDetailJob = async () => {
    onChangeCurrentStep?.(2);
  };

  return (
    <ResultAndAnalystContainer className="flex flex-col items-center flex-1 w-full">
      <ResultHeader />

      <div className="flex flex-col bg-grey1D/[0.8] mt-8 md:mt-[38px] xl:mt-16 relative rounded-lg w-full">
        <div className={`flex flex-col ${isCollapse && "cv-content relative"}`}>
          <div
            className={`flex flex-row rounded-2xl w-full gap-2 ${isCollapse && "cv-content shadow-base"}`}
          >
            <CVInfo />
          </div>
          {isCollapse && <BtnViewMore onHideCollapse={onHideCollapse} />}
        </div>
        {!isCollapse && <BtnViewLess onCollapse={onCollapse} />}
        <Divider />
        <Footer onViewDetailJob={onViewDetailJob} />
      </div>
      <a
        className="p-[11px] border border-grey3E/[0.4] bg-gray1D/[0.5] rounded-2xl absolute right-16 bottom-16"
        href="https://forms.gle/ZsL2rGKXmjpx422J6"
        target="_blank"
      >
        <img src={icChatBuble} />
      </a>
    </ResultAndAnalystContainer>
  );
}

export default React.memo(ResultAnalystPage);
