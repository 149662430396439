import React from "react";
function ButtonSelectCv({
  children,
  title,
  description,
  img
}: {
  children: JSX.Element;
  title: string;
  description: string;
  img: string;
}) {
  return (
    <div className="cv-type rounded-lg flex flex-col shadow-base p-4 sm:p-6 md:w-[50%] xl:w-[402px] w-full">
      <div className="flex flex-row items-center md:gap-2 gap-3 xl:gap-4 mb-4 w-full">
        <img
          src={img}
          className="w-12 h-12 md:w-[43px] md:h-[43px] xl:w-[62px] xl:h-[62px]"
        />
        <div className="flex flex-col flex-1 overflow-hidden">
          <span className="text-base/[28px] md:text-base/[20px] xl:text-lg  text-white font-semibold line-clamp-1">
            {title}
          </span>
          <div className="text-xs/[20px] sm:text-[13px] text-greyAD font-medium sm:mt-1 truncate">
            {description}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
export default React.memo(ButtonSelectCv);
