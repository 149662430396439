import { imgLogo } from "@assets/images";
import { imgFilter } from "@assets/svg";
import { ModalRef } from "@pages/select_cv/ListCVTemplate";
import React from "react";
import ModalLoginSocial from "./components/ModalLoginSocial";
import { HomeContainer } from "./styles";
export const modalLoginSocialRef = React.createRef<{
  onHide: () => void;
  onShow: (props?: { scanTimes?: number }) => void;
}>();
export const listTemplateRef = React.createRef<ModalRef>();
export default function HomePage() {
  const onLoginGoogle = () => {
    modalLoginSocialRef.current?.onShow();
  };
  return (
    <HomeContainer className="w-full h-dvh overflow-hidden">
      <div className="flex flex-col h-full items-center justify-between py-[34px] md:pt-[140px] xl:pt-[120px] sm:pb-[60px] md:px-[122px] px-[16px]">
        <img src={imgLogo} className=" sm:w-[182px] w-[141px] object-contain" />
        <div className="flex flex-col items-center">
          <div className="title text-2xl md:text-5xl/[56px] xl:text-7xl/[86px] font-semibold text-center">
            Đánh giá độ phù hợp
            <br /> ứng viên với công việc
          </div>
          <div className="description text-[13px]/21px md:text-2xl xl:text-[32px]/[44px] font-medium mt-6 text-center">
            Tự động phân tích - Loại bỏ thao tác thủ công
            <br /> - Ghép nối ứng viên với công việc
          </div>
          <div
            onClick={onLoginGoogle}
            className="button px-[21px] py-[10px] sm:px-[55px] sm:py-[14px] text-sm sm:text-base/[20px] text-white mt-6 font-medium justify-center items-center gap-[6px] rounded-lg flex flex-row cursor-pointer"
          >
            <img src={imgFilter} />
            Trải nghiệm ngay...
          </div>
        </div>
        <div className="text-xs/[20px] font-normal sm:font-medium self-center xl:self-start sm:text-[13px] text-white">
          iVIEC Talent AI <span className="text-[12px]">©</span> 2024
        </div>
      </div>
      <ModalLoginSocial ref={modalLoginSocialRef} />
    </HomeContainer>
  );
}
