import useBoolean from "@hooks/useBoolean";
import { useGetFile } from "@hooks/useGetFile";
import { FIVE_MB, UPLOAD_FILE_STATUS } from "@utils/constants";
import { Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import {
  Fragment,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";

export type UploadWrapperChildProps = Partial<{
  data: UploadFile;
  isUploaded: boolean;
  isUploading: boolean;
  isFileTooLarge: boolean;
  handleUpdate: () => void;
  handleDelete: () => void;
  customUploadContent?: JSX.Element;
  scanTimes: number;
}>;

export type UploadWrapperProps = Omit<UploadProps, "onChange"> &
  Partial<{
    enableCrop: boolean;
    maxSize: number;
    isDraggable: boolean;
    value: string;
    errorMsg: ReactNode;
    children: ReactElement;
    removeFileNames: string[];
    onChange: (value: UploadFile | UploadFile[] | undefined) => void;
  }>;
const TOAST_ID = "UPLOAD_FILE";
export const UploadWrapper = (props: UploadWrapperProps) => {
  const {
    disabled,
    maxSize,
    value,
    errorMsg,
    children = <Fragment />,
    multiple,
    accept,
    removeFileNames,
    onChange
  } = props;

  const refChild = useRef<HTMLDivElement>(null);
  const requestUpdate = useRef(false);

  const [list, setList] = useState<UploadFile[]>(
    value ? [{ uid: value, name: "", response: value }] : []
  );
  const [isFileTooLarge, onFileTooLarge, onHideFileTooLarge] =
    useBoolean(false);
  const { length, [length - 1]: data } = list;
  const isError = data?.status === "error";
  const isUploaded = !!value;
  const isUploading = multiple
    ? list.length > 0 &&
      list.some((item) => item?.status === UPLOAD_FILE_STATUS.uploading)
    : data?.status === UPLOAD_FILE_STATUS.uploading;

  const [, fileName] = useGetFile(data?.name ? undefined : data?.response);

  const checkNameError = ({ type, name }: { name: string; type: string }) => {
    const fileTypesByName = `${name}`.split(".");
    return (
      !type &&
      fileTypesByName.length > 0 &&
      Array.isArray(removeFileNames) &&
      removeFileNames.includes(
        fileTypesByName[fileTypesByName.length - 1].toLowerCase()
      )
    );
  };

  const handleChange: UploadProps["onChange"] = async ({ fileList }) => {
    const newList = accept
      ? [...fileList].filter(
          (item) =>
            accept.includes(item?.type || "") &&
            !checkNameError({ type: item?.type || "", name: item?.name })
        )
      : [...fileList];
    let fileStatus = true;
    let sizes = 0;
    newList.forEach((item) => {
      if (item?.status) {
        fileStatus =
          fileStatus &&
          [UPLOAD_FILE_STATUS.done, UPLOAD_FILE_STATUS.error].includes(
            item?.status
          );
      } else {
        fileStatus = false;
      }
      sizes += item?.size || 0;
    });
    if (sizes > FIVE_MB) {
      toast.error("file ", { toastId: TOAST_ID });
      setList([]);
      return;
    }

    if (multiple && fileStatus) {
      setList([]);
    } else {
      setList(fileList);
    }
    onChange?.(fileList[fileList.length - 1]);
  };

  const handleUpdate = () => {
    if (disabled) return;

    handleDelete();
    requestUpdate.current = true;
  };

  const handleDelete = () => {
    if (disabled) return;

    setList([]);
    onHideFileTooLarge();
    onChange?.(multiple ? [] : undefined);
  };

  const renderItem = () => {
    return null;
  };

  const beforeUpload: UploadProps["beforeUpload"] = (
    { size, type, name },
    fileList
  ) => {
    const isError =
      accept && fileList.every((file) => !accept.includes(file?.type));
    const isNameError = checkNameError({ type, name });
    if ((isError || isNameError) && fileList.length > 0) {
      toast.error(errorMsg);
      return false;
    }
    if (accept && !accept.includes(type)) {
      return false;
    }
    if (maxSize && errorMsg && size > maxSize && !multiple) {
      onFileTooLarge();
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isError) return;
    toast.error("Đã xảy ra lỗi");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (value || !requestUpdate.current) return;

    requestUpdate.current = false;
    refChild.current?.click();
  }, [value]);

  const { type: Child, props: p } = cloneElement(children);

  return (
    <Upload
      {...props}
      disabled={disabled || isUploading || isUploaded}
      fileList={list}
      onChange={handleChange}
      itemRender={renderItem}
      beforeUpload={beforeUpload}
      accept={accept}
    >
      <div ref={refChild} />
      <Child
        {...props}
        {...p}
        data={{ ...(data || {}), name: data?.name || fileName }}
        fileList={list}
        isUploaded={isUploaded}
        isUploading={isUploading}
        isFileTooLarge={isFileTooLarge}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </Upload>
  );
};
