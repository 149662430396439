import React from "react";

function UserInfo({ image, txt }: { image: string; txt: string }) {
  if (!txt) {
    return <></>;
  }
  return (
    <div className="flex flex-row items-center gap-2 text-[13px] xl:text-sm text-greyAD">
      <img src={image} className="w-5 h-5" />
      {txt}
    </div>
  );
}

export default React.memo(UserInfo);
