import { icArrowBack, icMagic } from "@assets/svg";
import { PageContext } from "@components/private_route/PrivateRoute";
import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function Footer({ onViewDetailJob }: { onViewDetailJob: () => Promise<void> }) {
  const { onChangeCurrentStep } = useContext(PageContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = Object.fromEntries(searchParams);
  const jobId = params?.jobId;
  const onBack = () => {
    if (params?.jobId) {
      navigate("/");
      return;
    }
    onChangeCurrentStep?.(0);
  };
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="footer" onClick={onBack}>
        {jobId ? (
          <img src={icArrowBack} className={`rotate-180 image`} />
        ) : (
          <img src={icMagic} className="image hidden md:block" />
        )}
        {jobId ? "Trang chủ" : "Thử với CV khác"}
      </div>
      <div className="footer" onClick={onViewDetailJob}>
        Kết quả đánh giá
        <img src={icArrowBack} className={`hidden md:block image`} />
      </div>
    </div>
  );
}
export default React.memo(Footer);
